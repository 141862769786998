import { FC } from "react";
import { generatePath, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { isChatPath, useGetProfileMenuRoutes } from "../../../utils/navbar";
import { TMenuRoute } from "../../../routes";
import { useContextHospi } from "../../../context/ContextHospi";
import { useChat } from "../../../context/ContextChat/ContextChat";

import {
  StickyNavbarContainer,
  StickyNavbarItem,
  StyledStickyNavbar,
  UnreadInfo
} from "./styles";

export const MobileStickyNavbar: FC = () => {
  const profileNavbarRoutes = useGetProfileMenuRoutes();
  const { currentUserRole } = useContextHospi();
  const {
    state: { chatCounters }
  } = useChat();
  const { t, i18n } = useTranslation("header");
  const stickyNavbarRoutes: TMenuRoute[] = profileNavbarRoutes.filter(
    (item) => item.keyLocalize !== "blog"
  );
  const unreadCount =
    currentUserRole === "host"
      ? (chatCounters?.requested ?? 0) + (chatCounters?.unread_accepted ?? 0)
      : chatCounters?.unread_accepted ?? 0;

  return (
    <StyledStickyNavbar>
      <StickyNavbarContainer $itemsAmount={stickyNavbarRoutes.length}>
        {stickyNavbarRoutes.map((item) => (
          <StickyNavbarItem key={`mobileStickyNavbar${item.keyLocalize}`}>
            <NavLink
              to={generatePath(item.path, {
                lng: i18n.language,
                id: isChatPath(item.path) ? "all" : ""
              })}
            >
              {item.icon}
              <span>
                {t(`subMenu.${item.keyLocalize}`)}
                {unreadCount && item.keyLocalize === "chats" ? (
                  <UnreadInfo>
                    <span className="countInfo">{unreadCount}</span>
                  </UnreadInfo>
                ) : null}
              </span>
            </NavLink>
          </StickyNavbarItem>
        ))}
      </StickyNavbarContainer>
    </StyledStickyNavbar>
  );
};
