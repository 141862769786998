import styled from "styled-components/macro";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 16px);
  font-family: Inter, sans-serif;
  line-height: 1.5em;
`;

export const Inner = styled.div`
  max-width: 600px;
  font-size: 20px;
  text-align: center;
  margin: 30px;
`;

export const StyledError = styled.pre`
  height: 100px;
  overflow: auto;
  margin: 0 30px;
  font-family: monospace;
  max-width: 90%;
`;

export const ButtonsContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
`;
