import styled from "styled-components/macro";
import { StyledMenu } from "../ProfileMenu/styles";

export const LeftSideMenu = styled(StyledMenu)`
  left: 0;
  z-index: 20;
  width: 70%;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  & label {
    padding: 1.5rem 0 0 1rem;
    text-align: start;
    @media ${(props) => props.theme.media.phone} {
      padding: 1.5rem 0 0 0;
    }
  }
  & a {
    text-align: start;

    @media ${(props) => props.theme.media.phone} {
      font-size: 1.5rem;
      text-align: center;
    }
  }
  @media ${(props) => props.theme.media.phone} {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const LanguageSection = styled.div`
  display: flex;
  margin: 0 1em;
  padding: 1.5rem 0 0 0;
  & > div {
    width: max-content;
  }
  & > div > div {
    font-size: ${(props) => props.theme.font.sizeTitle};
    color: ${(props) => props.theme.colors.text};
  }
  & .panel {
    left: 0;
  }

  @media screen and (max-height: 650px) {
    & .panel {
      top: -100px;
    }
  }

  @media ${(props) => props.theme.media.phone} {
    justify-content: center;
    padding: 1.5rem 0 0 0;
  }
`;
