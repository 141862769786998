import { createContext, FC, ReactNode, useContext, useState } from "react";

import { IUserShort } from "../interfaces/interfaces";
import { TPublicAccommodation } from "../assets/data/publicFindRoomAccommodations";

interface IContextPublicAccommodation {
  host: Partial<IUserShort>;
  accommodation: TPublicAccommodation;
  updateHost: (host: Partial<IUserShort>) => void;
  updateAccommodation: (accommodation: TPublicAccommodation) => void;
}

const ContextPublicAccommodation = createContext<IContextPublicAccommodation>({
  host: {},
  accommodation: {},
  updateHost: (_) => {},
  updateAccommodation: (_) => {}
});

export const useContextPublicAccommodation =
  (): IContextPublicAccommodation => {
    return useContext<IContextPublicAccommodation>(ContextPublicAccommodation);
  };

export const PublicAccommodationProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [host, setHost] = useState<Partial<IUserShort>>({});
  const [accommodation, setAccommodation] = useState<TPublicAccommodation>({});

  const updateHost = (host: Partial<IUserShort>) => {
    setHost(host);
  };
  const updateAccommodation = (accommodation: TPublicAccommodation) => {
    setAccommodation(accommodation);
  };

  return (
    <ContextPublicAccommodation.Provider
      value={{
        host,
        accommodation,
        updateHost,
        updateAccommodation
      }}
    >
      {children}
    </ContextPublicAccommodation.Provider>
  );
};
