import { DivContainer } from "./styles";
import styled from "styled-components/macro";
import { FC } from "react";
import { theme } from "../../styles/theme";

interface ILoader {
  size?: number; // ? Size roating element and font (em)
  text?: string;
  isLoading?: boolean;
  isModal?: boolean; // ? Modal mode
  whiteBackground?: boolean;
}

export const LoaderCustom: FC<ILoader> = ({
  size = 1,
  text,
  isLoading = true,
  isModal = true,
  whiteBackground = false
}) => {
  return (
    <DivContainer
      fontSize={`${size}em`}
      isLoading={isLoading}
      isModal={isModal}
      whiteBackground={whiteBackground}
    >
      {text !== undefined && <span>{`  ${text}`}</span>}
    </DivContainer>
  );
};

const ThreeDotsLoaderRoot = styled.div<{ color: string }>`
  .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(p) => p.color};
    color: ${(p) => p.color};
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(p) => p.color};
    color: ${(p) => p.color};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(p) => p.color};
    color: ${(p) => p.color};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: ${(p) => p.color};
    }
    50%,
    100% {
      background-color: ${(p) => `${p.color}33`};
    }
  }
`;

export const ThreeDotsLoader: FC<{ className?: string }> = ({ className }) => {
  return (
    <ThreeDotsLoaderRoot className={className} color={theme.colors.text}>
      <div className="dot-flashing"></div>
    </ThreeDotsLoaderRoot>
  );
};
