import { FC } from "react";
import styled from "styled-components/macro";

interface ISmallLoader {
  height: number;
  color: string;
  borderWidth?: number;
  minWidth?: number;
}

export const Loader = styled.div<{
  color?: string;
  height?: number;
  borderWidth?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: ${(props) => (props.height ? props.height : 30)}px;
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => (props.height ? props.height + 10 : 30)}px;
    height: ${(props) => (props.height ? props.height + 10 : 30)}px;
    margin: 8px;
    border: 3px solid
      ${(props) => (props.color ? props.color : props.theme.colors.text)};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) =>
        props.color ? props.color : props.theme.colors.text}
      transparent transparent transparent;
    border-width: ${(props) => (props.borderWidth ? props.borderWidth : 3)}px;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SmallLoader: FC<ISmallLoader> = (props) => {
  return (
    <Loader style={{ width: props.minWidth }} {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Loader>
  );
};
