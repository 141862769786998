import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div`
  background: ${theme.colors.text};
  padding: 64px 80px;
  display: flex;
  flex-direction: column;
  p {
    color: white;
    font-size: 16px;
    margin: 0;
  }
  & > div {
    width: 100%;
    display: flex;
  }
  & ul {
    list-style: none;
    & li a {
      color: white;
      font-size: 16px;
    }
    & li {
      padding-top: 16px;
    }
  }
  @media (max-width: 910px) {
    padding: 64px 40px;
  }
  @media (max-width: 820px) {
    padding: 64px 20px;
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  padding: 20px 0 50px;
  & > div {
    flex: 1;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 0;
    & > div {
      flex: 0;
      margin-bottom: 30px;
    }
  }
`;

export const FooterRow = styled.div`
  display: flex;

  .oneSubMenuColumn {
    flex: 25%;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .oneSubMenuColumn {
      flex: 50%;
    }
  }
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 14px;
  color: ${theme.colors.textDimmed};
  font-weight: 600;
  letter-spacing: 0.5px;
  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      flex: 0;
    }
  }
`;

export const MailSection = styled.div`
  border-bottom: 1px solid #6f7f9e;
  padding: 0 0 37px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & input {
    height: 42px;
    //max-width: 282px;
    //width: 100%;
    margin-right: 12px;
  }
  & button {
    font-size: 16px;
    height: 42px;
  }
  @media (max-width: 675px) {
    align-items: flex-start;
    flex-direction: column;
    & input {
      max-width: 100%;
      width: 100%;
      margin: 12px 0;
    }
    & div + div {
      width: 100%;
      flex-direction: column;
    }
  }
`;

export const LanguageSection = styled.div`
  flex: 33%;
  & ul li {
    padding: 8px;
  }
  @media (max-width: 768px) {
    flex: auto;
  }
`;

export const CustomRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 75%;
  margin-bottom: 20px;

  .wideSubMenu {
    flex: 67%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    flex: 50%;
    .wideSubMenu {
      flex: auto;
      margin-bottom: 30px;
    }
  }
`;

export const LanguageWide = styled(LanguageSection)`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input<{ error: boolean }>`
  border-radius: 6px;
  padding: 9px 16px;
  border: 1px solid ${({ error }) => (error ? "red" : theme.colors.border)};
  color: ${theme.colors.textDimmed};
`;

export const CopyRightSection = styled.div`
  color: #9ca3af;
  font-size: 16px;
  margin-top: 32px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  & svg {
    margin-left: 24px;
  }
  @media (max-width: 620px) {
    & #kvk {
      display: none;
    }
  }
`;

export const CustomLink = styled.a`
  color: white;
  font-size: 16px;
  padding-top: 16px;
  display: inline-block;
  &:hover {
    color: white;
  }
`;
