import { FC, ReactNode, Suspense } from "react";
import styled from "styled-components/macro";
import { LoaderCustom } from "../Loader";

const Root = styled.div<{ $visible: boolean }>`
  background: white;
  display: ${({ $visible }) => ($visible ? "flex" : "none")};
`;
const Content = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const Layout: FC<{
  children: ReactNode;
  visible?: boolean;
}> = ({ children, visible = true }) => {
  return (
    <Root $visible={visible}>
      <Content>
        <Suspense
          fallback={<LoaderCustom size={5} text="" whiteBackground={true} />}
        >
          {children}
        </Suspense>
      </Content>
    </Root>
  );
};
