import {
  init,
  browserTracingIntegration,
  replayIntegration
} from "@sentry/react";

export const initSentry = () => {
  if (window.location.hostname !== "localhost") {
    init({
      dsn: "https://dc7a1c4daabf44049457cdbe5501ac4f@o4505130016374784.ingest.sentry.io/4505130675601408",
      integrations: [browserTracingIntegration(), replayIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      normalizeDepth: 4,
      environment: process.env.NODE_ENV
    });
  }
};
