import { ISelectItem } from "../interfaces/interfaces";
import { countryData } from "../assets/data/isoNameCountries";
import { Languages, TLang } from "assets/data/isoNameLanguage";

export const getCountries = (lang: TLang = "GB"): ISelectItem[] => {
  function sortItemsLocale<T extends ISelectItem>(a: T, b: T): number {
    if (!a.hasOwnProperty("label") || a.label === undefined) return 0;
    let aLabel = a.label.toLowerCase(),
      bLabel = b.label.toLowerCase();
    return aLabel.localeCompare(bLabel, lang);
  }
  return [
    ...countryData.map((item) => ({
      value: item["code"],
      label: item.name[lang]
    }))
  ].sort(sortItemsLocale);
};

export const sortItems = <T extends { label: string }>(a: T, b: T) => {
  if (!a.hasOwnProperty("label") || a.label === undefined) return 0;
  let aLabel = a.label.toLowerCase(),
    bLabel = b.label.toLowerCase();
  if (aLabel < bLabel) return -1;
  if (aLabel > bLabel) return 1;
  return 0;
};

export const sortTop = (
  items: ISelectItem[],
  topVal: string[] | undefined
): ISelectItem[] => {
  if (topVal === undefined || !topVal.length) return items;

  const newArray: ISelectItem[] = items.filter((item) =>
    topVal
      .map((item) => item.toLocaleUpperCase())
      .includes(item.value as string)
  );
  return newArray.concat(
    items.filter(
      (item) =>
        !topVal
          .map((item) => item.toLocaleUpperCase())
          .includes(item.value as string)
    )
  );
};

export const getNameCountry = (x: string, lang: TLang = "GB") => {
  const country = getCountries(lang).filter((item) => item.value === x);
  return country.length ? country[0].label : "";
};

export const getPhoneCodes = (
  options: {
    language: TLang;
    showCountryNames: boolean;
  } | null = null
): ISelectItem[] => {
  const temp: string[] = [];
  return countryData
    .map((item) => ({
      value: item["dial_code"],
      label: options?.showCountryNames
        ? `${String(item["dial_code"])} ${String(
            item["name"][options.language]
          )}`
        : String(item["dial_code"])
    }))
    .filter((x) => {
      if (temp.includes(x.value) || !x.value) return false;
      temp.push(x.value);
      return true;
    })
    .sort(sortItems);
};

export const getLanguage = (lang: TLang = "GB"): ISelectItem[] =>
  Languages.map((item) => ({ value: item.value, label: item.label[lang] }));

export const arrayLanguages = (keys: string[], lang: TLang = "GB"): string[] =>
  getLanguage(lang)
    .filter((item) => keys.includes(item.value as string))
    .map((item) => item.label);
