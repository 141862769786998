import styled, { css } from "styled-components/macro";
import { Button as OriginalButton } from "antd";
import { ReactNode } from "react";

const Root = styled.div`
  display: flex;
  gap: 24px;
`;

type TButtonSelect<TValue extends string | number> = {
  value: TValue;
  label: string;
  icon?: ReactNode;
};

const Button = styled(OriginalButton)<{ $selected: boolean }>`
  padding: 16px;
  font-size: 16px;
  height: auto;
  ${(p) =>
    p.$selected
      ? css`
          border: 1px solid ${(p) => p.theme.colors.primary};
          background: #fff7f3;

          &[disabled] {
            font-weight: 500;
            background-color: rgba(0, 0, 0, 0.1);
          }
        `
      : css``}
`;

export const ButtonSelect = <TValue extends string | number>({
  options,
  value,
  onChange,
  disabled,
  className,
  extraButtons
}: {
  options: TButtonSelect<TValue>[];
  value?: TValue[] | null;
  onChange: (selectedValues: TValue[], newValue: TValue | null) => void;
  disabled?: boolean;
  className?: string;
  extraButtons?: ReactNode;
  gridTemplate?: string;
}) => {
  return (
    <Root className={className}>
      {extraButtons}
      {options.map((o) => (
        <Button
          $selected={!!value?.includes(o.value)}
          disabled={disabled}
          icon={o.icon}
          key={o.value}
          onClick={() => {
            if (!value || value?.indexOf(o.value) === -1) {
              onChange(value ? [...value, o.value] : [o.value], o.value);
            } else {
              onChange(
                value.filter((v) => v !== o.value),
                null
              );
            }
          }}
        >
          {o.label}
        </Button>
      ))}
    </Root>
  );
};
