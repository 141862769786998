import { Amplify } from "aws-amplify";
import { getHeaderAuth } from "../utils/utilAmplify";
import { API_PATH, API_PUBLIC_PATH } from "../utils/constants";
import { isLocalhost } from "../utils/helpers";

const endPointHospi = process.env.REACT_APP_AWS_ENDPOINT;

export enum ServiceName {
  Protected = "Protected",
  Public = "Public"
}

export const defaultAmplifyConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_POOL_ID,
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: isLocalhost
        ? `http://localhost:3000/`
        : process.env.REACT_APP_OAUTH_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_URL,
      responseType: "code",
      socialProviders: ["Google"]
    }
  },
  API: {
    endpoints: [
      {
        name: ServiceName.Protected,
        endpoint: endPointHospi + API_PATH,
        region: process.env.REACT_APP_REGION,
        custom_header: getHeaderAuth
      },
      {
        name: ServiceName.Public,
        endpoint: endPointHospi + API_PUBLIC_PATH,
        region: process.env.REACT_APP_REGION,
        custom_header: getHeaderAuth
      }
    ]
  }
};

export const initAmplify = (): void => {
  Amplify.configure(defaultAmplifyConfig);
};
