import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div`
  display: flex;
  max-width: 1360px;
  width: 100%;
  margin: 64px auto;
  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-size: 40px;
  margin-bottom: 24px;
  color: ${theme.colors.text};
  & span {
    color: ${theme.colors.primary};
  }
  @media (max-width: 475px) {
    font-size: 32px;
  }
  @media (max-width: 375px) {
    font-size: 28px;
  }
`;

export const CardList = styled.div`
  padding: 0 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const Card = styled.div<{ i: boolean }>`
  padding: 24px;
  background: ${theme.colors.bgSecondary};
  border-radius: 12px;
  width: 30%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  & img {
    object-fit: cover;
    height: 180px;
    width: 100%;
    margin-bottom: 24px;
    border-radius: 6px;
  }
  & button {
    margin-top: auto;
  }
  & span {
    color: ${theme.colors.text};
  }
  & p {
    margin-top: 8px;
    color: ${theme.colors.textGray};
  }
  font-size: 20px;
  @media (max-width: 900px) {
    width: 80%;
    & img {
      height: ${(props) => (props.i ? "45%" : "71%")};
    }
  }
  @media (max-width: 600px) {
    & img {
      height: ${(props) => (props.i ? "40%" : "71%")};
    }
  }
  @media (max-width: 450px) {
    & img {
      height: ${(props) => (props.i ? "35%" : "71%")};
    }
  }
  @media (max-width: 900px) {
    width: 90%;
  }
`;
