export class EventEmitter {
  _events: { [key: string]: ((...args: any[]) => void)[] } = {};

  on(eventName: string, callback: (...args: any[]) => void) {
    if (!this._events[eventName]) {
      this._events[eventName] = [];
    }

    this._events[eventName].push(callback);

    return this;
  }

  off(eventName: string, callback: (...args: any[]) => void) {
    if (this._events[eventName]) {
      const callbackIndex = this._events[eventName].findIndex(
        (e) => e === callback
      );
      if (callbackIndex !== -1) {
        this._events[eventName].splice(callbackIndex, 1);
      }
    }

    return this;
  }

  emit(eventName: string, ...args: any[]) {
    if (this._events[eventName]) {
      this._events[eventName].forEach((callback) => {
        callback.apply(null, args);
      });
    }
  }
}
