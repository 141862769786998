import { FC } from "react";
import { Card, CardList, Title, Wrapper } from "./styles";
import { StyledButton } from "components/generic";
import HP_people1 from "assets/images/Hospita en student voor het tuinhuisje.webp";
import HP_people2 from "assets/images/Buitenlandse student en hospita in de keuken.webp";
import HP_people3 from "assets/images/Gastgezin voor hun huis in Utrecht.webp";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ICardComponent {
  image: string;
  title: string;
  text: string;
  buttonText: string;
  link: string;
  alttext: string;
  i: number;
}

const CardComponent: FC<ICardComponent> = ({
  image,
  text,
  buttonText,
  title,
  link,
  alttext,
  i
}) => {
  const navigate = useNavigate();

  return (
    <Card i={i === 2}>
      <img src={image} alt={alttext} loading="lazy" />
      <span id="bold">{title}</span>
      <p>{text}</p>
      <StyledButton outline={true} onClick={() => navigate(link)}>
        {buttonText}
      </StyledButton>
    </Card>
  );
};

export const WeAreHereSection = () => {
  const { t, i18n } = useTranslation("home");

  const cardsData = [
    {
      image: HP_people1,
      title: t("forHosts"),
      text: t("weAreHere.text1"),
      buttonText: t("buttons.moreAboutRentingOut"),
      link: `/${i18n.language}/hospita`,
      alttext: t("weAreHere.text1alt")
    },
    {
      image: HP_people2,
      title: t("forStudents"),
      text: t("weAreHere.text2"),
      buttonText: t("buttons.moreAboutRent"),
      link: `/${i18n.language}/students`,
      alttext: t("weAreHere.text2alt")
    },
    {
      image: HP_people3,
      title: t("forCities"),
      text: t("weAreHere.text3"),
      buttonText: t("buttons.contact"),
      link: `/${i18n.language}/partners`,
      alttext: t("weAreHere.text3alt")
    }
  ];

  return (
    <Wrapper>
      <div>
        <Title>
          {t("weAreHere.title.p1")} <span>{t("weAreHere.title.p2")}</span>
        </Title>
        <CardList>
          {cardsData.map((props, i) => (
            <CardComponent {...props} i={i} key={`weAreHereCard${i}`} />
          ))}
        </CardList>
      </div>
    </Wrapper>
  );
};
