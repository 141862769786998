import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div`
  max-width: 1360px;
  margin: 70px auto 0 auto;
  & > div {
    padding: 0 124.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 910px) {
      padding: 0 40px;
    }
    @media (max-width: 820px) {
      padding: 0 20px;
    }
  }
`;

export const Title = styled.div`
  font-size: 40px;
  margin-bottom: 24px;
  color: ${theme.colors.text};
  & span {
    color: ${theme.colors.primary};
  }
  @media (max-width: 475px) {
    font-size: 32px;
  }
  @media (max-width: 425px) {
    width: 100%;
    text-align: left;
  }
  @media (max-width: 375px) {
    font-size: 28px;
  }
`;

export const ListWrapper = styled.div`
  width: 100%;
  & > div > div:first-child {
    display: flex;
    justify-content: center;
    @media (max-width: 425px) {
      justify-content: flex-start;
    }
  }
`;
export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  margin-top: 30px;
  & h2 {
    font-weight: 600;
    font-size: 22px;
    color: ${theme.colors.text};
    @media (max-width: 570px) {
      margin-top: 32px;
    }
  }
  & p {
    color: ${theme.colors.textDimmed};
    font-size: 20px;
  }
  & div + div {
    align-items: flex-start;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 42%;
  }
  & img {
    padding: 0 0 0 80px;
    width: 120%;
  }
  &:nth-child(2n) {
    flex-direction: row-reverse;
    & img {
      //width: 99%;
      padding: 0 80px 0 0;
    }
    & div + div {
      align-items: flex-end;
    }
    & p {
      text-align: right;
    }
    @media (max-width: 1024px) {
      & img {
        padding: 0;
      }
      & p {
        text-align: left;
      }
      & div + div {
        margin-right: 10px;
        margin-left: 0;
        align-items: flex-start;
      }
      & > div {
        width: 100%;
      }
    }
    @media (max-width: 570px) {
      flex-direction: column;
    }
  }
  @media (max-width: 1024px) {
    & > div {
      width: 100%;
    }
    & div + div {
      margin-left: 10px;
    }
    & img {
      padding: 0;
      width: 99%;
    }
  }
  @media (max-width: 570px) {
    flex-direction: column;
    & div + div {
      margin: 0;
    }
  }
`;
