import {
  EMPTY_STEP_METADATA,
  IWizardHostState,
  T_WIZARD_HOST_STEP,
  TStepConfiguration,
  WIZARD_HOST_STEPS,
  WizardStepComponentType
} from "../interfaces";
import { StepContainerWide } from "../Student/styles";
import { WizardStepPersonalInfo } from "./Steps/WizardStepPersonalInfo";
import { WizardStepAvatar } from "./Steps/WizardStepAvatar";
import { WizardStepReferral } from "./Steps/WizardStepReferral";
import { WizardStepWelcome } from "./Steps/WizardStepWelcome";

export const WIZARD_HOST_STEPS_ORDER: T_WIZARD_HOST_STEP[] = [
  WIZARD_HOST_STEPS.Welcome,
  WIZARD_HOST_STEPS.PersonalInfo,
  WIZARD_HOST_STEPS.Avatar,
  WIZARD_HOST_STEPS.Referral
];

export const WIZARD_HOST_DEFAULT_STATE: IWizardHostState = {
  metadata: {
    started: false,
    finished: false,
    created_at: null,
    updated_at: null,
    most_recent_active_step: null
  },
  personal_info: {
    metadata: EMPTY_STEP_METADATA,
    birth_country: null,
    birth_date: null
  },
  avatar: null,
  // planed_intake: null,
  referral: {
    metadata: EMPTY_STEP_METADATA,
    reason_to_become_host: [],
    source_to_hospi: [],
    experience_with_hosting: null
  }
};

export const WIZARD_HOST_STEPS_CONFIGURATION: {
  [key in T_WIZARD_HOST_STEP]?: TStepConfiguration<
    IWizardHostState,
    T_WIZARD_HOST_STEP
  >;
} = {
  [WIZARD_HOST_STEPS.Welcome]: {
    component: WizardStepWelcome,
    container: StepContainerWide,
    type: WizardStepComponentType.Static
  },
  [WIZARD_HOST_STEPS.PersonalInfo]: {
    component: WizardStepPersonalInfo,
    type: WizardStepComponentType.Editable
  },
  [WIZARD_HOST_STEPS.Avatar]: {
    component: WizardStepAvatar,
    type: WizardStepComponentType.Editable
  },
  [WIZARD_HOST_STEPS.Referral]: {
    component: WizardStepReferral,
    type: WizardStepComponentType.Editable
  }
};
