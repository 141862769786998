import { useEffect, useRef } from "react";

export const useScrollBottomHandler = ({
  handler
}: {
  handler: () => void;
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = elementRef.current;
    const eventHandler = (event: Event) => {
      if (!event.target) return;
      const { scrollHeight, scrollTop, clientHeight } =
        event.target as HTMLDivElement;

      if (scrollHeight === clientHeight) {
        return;
      }

      if (Math.abs(scrollHeight - clientHeight - scrollTop) === 0) {
        handler();
      }
    };
    element?.addEventListener("scroll", eventHandler);

    return () => {
      element?.removeEventListener("scroll", eventHandler);
    };
  }, [handler]);

  return elementRef;
};

export const addScrollOnTopListener = (
  element: HTMLDivElement,
  handler: () => void,
  threshold: number = 2
) => {
  const eventHandler = (event: Event) => {
    if (!event.target) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } =
      event.target as HTMLDivElement;

    if (scrollHeight === clientHeight) {
      return;
    }

    if (scrollTop <= threshold) {
      handler();
    }
  };

  if (element) {
    element.addEventListener("scroll", eventHandler);
  }

  return () => {
    element.removeEventListener("scroll", eventHandler);
  };
};

export const scrollElementTo = (element: HTMLElement, y: number) => {
  if (typeof element.scrollTo === "function") {
    element.scrollTo(0, y);
  } else {
    // EDGE fix
    element.scrollTop = y;
  }
};

export const useUpdateAppHeightCssVariable = () => {
  useEffect(() => {
    const updateAppHeight = () => {
      document.documentElement.style.setProperty(
        "--app-height",
        `${window.innerHeight}px`
      );
    };

    window.addEventListener("resize", updateAppHeight);
    updateAppHeight();
    return () => {
      window.removeEventListener("resize", updateAppHeight);
    };
  }, []);
};

export const scrollToFormWithError = (element: HTMLDivElement) => {
  setTimeout(() => {
    element?.querySelector(".ant-form-item-has-error")?.scrollIntoView();
  }, 100);
};
