import {
  IWizardHostState,
  TWizardStepComponentEditable,
  WIZARD_HOST_STEPS,
  WIZARD_HOST_STEPS_PATHS
} from "../../../interfaces";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserAPI } from "../../../../../API/userApi";
import { RcFile } from "antd/lib/upload/interface";
import { getStepErrorsFromResponse } from "../../../utils";
import { WizardStepAvatarView } from "../../../Student/Steps/WizardStepAvatar";
import { useContextHospi } from "../../../../../context/ContextHospi";
import {
  getImageUploadFileSize,
  compressUploadedImageFile
} from "../../../../../utils/helpers";
import { FILE_UPLOAD_IMAGES_ALLOWED_MB_SIZE } from "../../../../../config/common";

const statePath = WIZARD_HOST_STEPS_PATHS[WIZARD_HOST_STEPS.Avatar];
export const WizardStepAvatar: TWizardStepComponentEditable<IWizardHostState> =
  forwardRef(({ state, viewonly, readonly }, ref) => {
    const [errors, setErrors] = useState<{ user_image: string } | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { cognitoUser } = useContextHospi();
    const { t } = useTranslation("wizard");

    useImperativeHandle(ref, () => ({
      save: async () => {
        setErrors(null);
        if (!selectedFile && state[statePath]?.avatar_thumbnail_url) {
          return Promise.resolve(state);
        }

        let fileToSend = selectedFile;
        if (
          selectedFile &&
          selectedFile.size >
            getImageUploadFileSize(FILE_UPLOAD_IMAGES_ALLOWED_MB_SIZE)
        ) {
          try {
            fileToSend = await compressUploadedImageFile(selectedFile, true);
          } catch (error) {
            setErrors({
              user_image: t("step.Avatar.uploadError")
            });
            return Promise.reject(error);
          }
        }

        return await UserAPI.wizardHostSaveAvatar({
          user_image: fileToSend ? (fileToSend as RcFile) : null
        })
          .then((result) => {
            window.dataLayer.push({
              event: "Host_signup_1.3_host_profile_picture",
              user_id: cognitoUser?.id,
              user_type: "host"
            });
            return {
              ...state,
              [statePath]: result
            };
          })
          .catch((e) => {
            const errors = getStepErrorsFromResponse(e) as {
              user_image: string;
            } | null;
            if (errors) {
              setErrors(errors);
            } else {
              setErrors({
                user_image:
                  e?.response?.status === 413
                    ? t("step.Avatar.toBig")
                    : t("step.Avatar.uploadError")
              });
            }

            return Promise.reject(e);
          });
      }
    }));

    return (
      <WizardStepAvatarView
        avatarThumbnailUrl={state[statePath]?.avatar_thumbnail_url}
        errors={errors}
        onFileChange={(file) => {
          setSelectedFile(file);
        }}
        readonly={readonly}
        viewonly={viewonly}
      />
    );
  });
