import { FC, createContext, useContext, useEffect, ReactNode } from "react";
import { notification } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";

declare global {
  interface Window {
    hospiNotifier: NotificationInstance;
  }
}

const ContextNotifier = createContext<NotificationInstance>(
  null as unknown as NotificationInstance
);

export const useNotifier = () => {
  return useContext(ContextNotifier);
};

export const NotifierProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    // make api accessible globally
    // didn't find a better way to do it
    window.hospiNotifier = api;
  }, [api]);

  return (
    <ContextNotifier.Provider value={api}>
      {children}
      {contextHolder}
    </ContextNotifier.Provider>
  );
};
