export type TLang = "GB" | "NL";

export const Languages = [
  { value: "AF", label: { GB: "Afrikaans", NL: "Afrikaans" } },
  { value: "SQ", label: { GB: "Albanian", NL: "Albanees" } },
  { value: "AR", label: { GB: "Arabic", NL: "Arabisch" } },
  { value: "HY", label: { GB: "Armenian", NL: "Armeens" } },
  { value: "EU", label: { GB: "Basque", NL: "baskisch" } },
  { value: "BN", label: { GB: "Bengali", NL: "Bengaals" } },
  { value: "BG", label: { GB: "Bulgarian", NL: "Bulgaars" } },
  { value: "CA", label: { GB: "Catalan", NL: "Catalaans" } },
  { value: "KM", label: { GB: "Cambodian", NL: "Cambodjaans" } },
  { value: "ZH", label: { GB: "Chinese", NL: "Chinese" } },
  { value: "HR", label: { GB: "Croatian", NL: "Kroatisch" } },
  { value: "CS", label: { GB: "Czech", NL: "Tsjechisch" } },
  { value: "DA", label: { GB: "Danish", NL: "Deens" } },
  { value: "NL", label: { GB: "Dutch", NL: "Nederlands" } },
  { value: "GB", label: { GB: "English", NL: "Engels" } },
  { value: "ET", label: { GB: "Estonian", NL: "Ests" } },
  { value: "FJ", label: { GB: "Fiji", NL: "Fiji" } },
  { value: "FI", label: { GB: "Finnish", NL: "Fins" } },
  { value: "FR", label: { GB: "French", NL: "Frans" } },
  { value: "KA", label: { GB: "Georgian", NL: "Georgisch" } },
  { value: "DE", label: { GB: "German", NL: "Duits" } },
  { value: "EL", label: { GB: "Greek", NL: "Grieks" } },
  { value: "GU", label: { GB: "Gujarati", NL: "Gujarati" } },
  { value: "HE", label: { GB: "Hebrew", NL: "Hebreeuws" } },
  { value: "HI", label: { GB: "Hindi", NL: "Hindi" } },
  { value: "HU", label: { GB: "Hungarian", NL: "Hongaars" } },
  { value: "IS", label: { GB: "Icelandic", NL: "IJslands" } },
  { value: "ID", label: { GB: "Indonesian", NL: "Indonesisch" } },
  { value: "GA", label: { GB: "Irish", NL: "Iers" } },
  { value: "IT", label: { GB: "Italian", NL: "Italiaans" } },
  { value: "JA", label: { GB: "Japanese", NL: "Japans" } },
  { value: "JW", label: { GB: "Javanese", NL: "Javaans" } },
  { value: "KO", label: { GB: "Korean", NL: "Koreaans" } },
  { value: "LA", label: { GB: "Latin", NL: "Latijns" } },
  { value: "LV", label: { GB: "Latvian", NL: "Les" } },
  { value: "LT", label: { GB: "Lithuanian", NL: "Litouws" } },
  { value: "MK", label: { GB: "Macedonian", NL: "Macedonisch" } },
  { value: "MS", label: { GB: "Malay", NL: "Maleis" } },
  { value: "ML", label: { GB: "Malayalam", NL: "Malayalam" } },
  { value: "MT", label: { GB: "Maltese", NL: "Maltees" } },
  { value: "MI", label: { GB: "Maori", NL: "Maori" } },
  { value: "MR", label: { GB: "Marathi", NL: "Marathi" } },
  { value: "MN", label: { GB: "Mongolian", NL: "Mongools" } },
  { value: "NE", label: { GB: "Nepali", NL: "Nepalees" } },
  { value: "NO", label: { GB: "Norwegian", NL: "Noors" } },
  { value: "FA", label: { GB: "Persian", NL: "Perzisch" } },
  { value: "PL", label: { GB: "Polish", NL: "Pools" } },
  { value: "PT", label: { GB: "Portuguese", NL: "Portugees" } },
  { value: "PA", label: { GB: "Punjabi", NL: "Punjabi" } },
  { value: "QU", label: { GB: "Quechua", NL: "Quechua" } },
  { value: "RO", label: { GB: "Romanian", NL: "Roemeens" } },
  { value: "RU", label: { GB: "Russian", NL: "Russisch" } },
  { value: "SM", label: { GB: "Samoan", NL: "Samoaans" } },
  { value: "SR", label: { GB: "Serbian", NL: "Servisch" } },
  { value: "SK", label: { GB: "Slovak", NL: "Slowaaks" } },
  { value: "SL", label: { GB: "Slovenian", NL: "Sloveens" } },
  { value: "ES", label: { GB: "Spanish", NL: "Spaans" } },
  { value: "SW", label: { GB: "Swahili", NL: "Swahili" } },
  { value: "SV", label: { GB: "Swedish", NL: "Zweeds" } },
  { value: "TA", label: { GB: "Tamil", NL: "Tamil" } },
  { value: "TT", label: { GB: "Tatar", NL: "Tataars" } },
  { value: "TE", label: { GB: "Telugu", NL: "Telugu" } },
  { value: "TH", label: { GB: "Thai", NL: "Thais" } },
  { value: "BO", label: { GB: "Tibetan", NL: "Tibetaans" } },
  { value: "TO", label: { GB: "Tonga", NL: "Tonga" } },
  { value: "TR", label: { GB: "Turkish", NL: "Turks" } },
  { value: "UK", label: { GB: "Ukrainian", NL: "Oekraïens" } },
  { value: "UR", label: { GB: "Urdu", NL: "Urdu" } },
  { value: "UZ", label: { GB: "Uzbek", NL: "Oezbeeks" } },
  { value: "VI", label: { GB: "Vietnamese", NL: "Vietnamees" } },
  { value: "CY", label: { GB: "Welsh", NL: "Welsh" } },
  { value: "XH", label: { GB: "Xhosa", NL: "Xhosa" } }
];
