import styled from "styled-components/macro";

export default styled.svg.attrs({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink"
})``;

export interface ISvg {
  className?: string;
}
