import { Dispatch, FC, memo, SetStateAction, useRef } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, NavLink } from "react-router-dom";

import useOnClickOutside from "../../../utils/hooks/useOnClickOutside";
import { useGetHelpNavbarRoutes } from "../../../utils/navbar";

import { ContainerPopupMenu, Item, MenuItem, ArrowDown } from "../styles";
import { HelpNavbarItem } from "./styles";

type THelpNavbarItemProps = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  setIsProfileNavbarShown: Dispatch<SetStateAction<boolean>>;
  children?: never;
};

export const HelpNavbar: FC<THelpNavbarItemProps> = memo(
  ({ isShown, setIsShown, setIsProfileNavbarShown }) => {
    const ref = useRef<HTMLUListElement | null>(null);
    useOnClickOutside(ref, () => setIsShown(false));
    const routeItems = useGetHelpNavbarRoutes();
    const { t, i18n } = useTranslation("header");

    return (
      <MenuItem
        $isUserAuthorized={true}
        style={{ position: "relative" }}
        onClick={() => {
          if (!isShown) setIsProfileNavbarShown(false);
          setIsShown((prev) => !prev);
        }}
      >
        <HelpNavbarItem>
          {t("subMenu.help")}
          <ArrowDown size="1.75rem" up={isShown} />
        </HelpNavbarItem>
        {isShown && (
          <ContainerPopupMenu className="helpNavbar" ref={ref}>
            {routeItems.map((item) => (
              <Item key={`helpNavbarItem${item.keyLocalize}`}>
                <NavLink
                  to={generatePath(item.path, {
                    lng: i18n.language
                  })}
                >
                  {t(`subMenu.${[item.keyLocalize]}`)}
                </NavLink>
              </Item>
            ))}
          </ContainerPopupMenu>
        )}
      </MenuItem>
    );
  }
);
