import { FC, memo } from "react";
import { Content, Wrapper } from "./styles";
import { QuotesIco } from "../../components/Icons/Quotes";
import { StyledButton } from "components/generic";
import { theme } from "../../styles/theme";

type TReviewProps = {
  reviewText: string;
  authorName: string;
  authorInfo: string;
  src?: string;
  btnText?: string;
  children?: never;
};

export const Review: FC<TReviewProps> = memo(
  ({ reviewText, authorName, authorInfo, src, btnText }) => {
    return (
      <Wrapper $isImg={!!src}>
        <div>
          {src && (
            <img src={src} alt="" width={202} height={304} loading="lazy" />
          )}
          <Content $isImg={!!src}>
            {QuotesIco}
            <p>{reviewText}</p>
            <div>
              <div>
                <span style={{ color: theme.colors.text }} id="bold">
                  {authorName}
                </span>
                <span style={{ color: theme.colors.primary }}>
                  {authorInfo}
                </span>
              </div>
              {btnText && (
                <StyledButton outline={true} p="16px 32px">
                  <a
                    href="https://www.google.com/search?q=hospi+housing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {btnText}
                  </a>
                </StyledButton>
              )}
            </div>
          </Content>
        </div>
      </Wrapper>
    );
  }
);
