export const countryData = [
  {
    name: { GB: "Afghanistan", NL: "Afghanistan" },
    dial_code: "+93",
    code: "AF"
  },
  { name: { GB: "Albania", NL: "Albanië" }, dial_code: "+355", code: "AL" },
  { name: { GB: "Algeria", NL: "Algerije" }, dial_code: "+213", code: "DZ" },
  {
    name: { GB: "American Samoa", NL: "Amerikaans Samoa" },
    dial_code: "+1684",
    code: "AS"
  },
  { name: { GB: "Andorra", NL: "Andorra" }, dial_code: "+376", code: "AD" },
  { name: { GB: "Angola", NL: "Angola" }, dial_code: "+244", code: "AO" },
  { name: { GB: "Anguilla", NL: "Anguilla" }, dial_code: "+1264", code: "AI" },
  {
    name: { GB: "Antigua and Barbuda", NL: "Antigua en Barbuda" },
    dial_code: "+1268",
    code: "AG"
  },
  { name: { GB: "Argentina", NL: "Argentinië" }, dial_code: "+54", code: "AR" },
  { name: { GB: "Armenia", NL: "Armenië" }, dial_code: "+374", code: "AM" },
  { name: { GB: "Aruba", NL: "Aruba" }, dial_code: "+297", code: "AW" },
  { name: { GB: "Australia", NL: "Australië" }, dial_code: "+61", code: "AU" },
  { name: { GB: "Austria", NL: "Oostenrijk" }, dial_code: "+43", code: "AT" },
  {
    name: { GB: "Azerbaijan", NL: "Azerbeidzjan" },
    dial_code: "+994",
    code: "AZ"
  },
  { name: { GB: "Bahamas", NL: "Bahamas" }, dial_code: "+1242", code: "BS" },
  { name: { GB: "Bahrain", NL: "Bahrain" }, dial_code: "+973", code: "BH" },
  {
    name: { GB: "Bangladesh", NL: "Bangladesh" },
    dial_code: "+880",
    code: "BD"
  },
  { name: { GB: "Barbados", NL: "Barbados" }, dial_code: "+1246", code: "BB" },
  { name: { GB: "Belarus", NL: "Wit-Rusland" }, dial_code: "+375", code: "BY" },
  { name: { GB: "Belgium", NL: "België" }, dial_code: "+32", code: "BE" },
  { name: { GB: "Belize", NL: "Belize" }, dial_code: "+501", code: "BZ" },
  { name: { GB: "Benin", NL: "Benin" }, dial_code: "+229", code: "BJ" },
  { name: { GB: "Bermuda", NL: "Bermuda" }, dial_code: "+1441", code: "BM" },
  { name: { GB: "Bhutan", NL: "Bhutan" }, dial_code: "+975", code: "BT" },
  { name: { GB: "Bolivia", NL: "Bolivia" }, dial_code: "+591", code: "BO" },
  {
    name: { GB: "Bonaire", NL: "Bonaire" },
    dial_code: "+599",
    code: "BQ"
  },
  {
    name: { GB: "Bosnia and Herzegovina", NL: "Bosnië-Herzegovina" },
    dial_code: "+387",
    code: "BA"
  },
  { name: { GB: "Botswana", NL: "Botswana" }, dial_code: "+267", code: "BW" },
  { name: { GB: "Brazil", NL: "Brazilië" }, dial_code: "+55", code: "BR" },
  {
    name: {
      GB: "British Indian Ocean Territory",
      NL: "Brits-Indisch oceaan gebied"
    },
    dial_code: "+246",
    code: "IO"
  },
  { name: { GB: "Bulgaria", NL: "Bulgarije" }, dial_code: "+359", code: "BG" },
  {
    name: { GB: "Burkina Faso", NL: "Burkina Faso" },
    dial_code: "+226",
    code: "BF"
  },
  { name: { GB: "Burundi", NL: "Burundi" }, dial_code: "+257", code: "BI" },
  { name: { GB: "Cambodia", NL: "Cambodja" }, dial_code: "+855", code: "KH" },
  { name: { GB: "Cameroon", NL: "Kameroen" }, dial_code: "+237", code: "CM" },
  { name: { GB: "Canada", NL: "Canada" }, dial_code: "+1", code: "CA" },
  {
    name: { GB: "Cape Verde", NL: "Kaapverdië" },
    dial_code: "+238",
    code: "CV"
  },
  {
    name: { GB: "Cayman Islands", NL: "Kaaiman Eilanden" },
    dial_code: "+345",
    code: "KY"
  },
  {
    name: {
      GB: "Central African Republic",
      NL: "Centraal Afrikaanse Republiek"
    },
    dial_code: "+236",
    code: "CF"
  },
  { name: { GB: "Chad", NL: "Tsjaad" }, dial_code: "+235", code: "TD" },
  { name: { GB: "Chile", NL: "Chili" }, dial_code: "+56", code: "CL" },
  { name: { GB: "China", NL: "China" }, dial_code: "+86", code: "CN" },
  {
    name: { GB: "Christmas Island", NL: "Kersteiland" },
    dial_code: "+61",
    code: "CX"
  },
  { name: { GB: "Colombia", NL: "Colombia" }, dial_code: "+57", code: "CO" },
  { name: { GB: "Comoros", NL: "Comoren" }, dial_code: "+269", code: "KM" },
  { name: { GB: "Congo", NL: "Congo" }, dial_code: "+242", code: "CG" },
  {
    name: { GB: "Cook Islands", NL: "Cook Eilanden" },
    dial_code: "+682",
    code: "CK"
  },
  {
    name: { GB: "Costa Rica", NL: "Costa Rica" },
    dial_code: "+506",
    code: "CR"
  },
  {
    name: { GB: "Cote d'Ivoire", NL: "Ivoorkust" },
    dial_code: "+225",
    code: "CI"
  },
  { name: { GB: "Croatia", NL: "Kroatië" }, dial_code: "+385", code: "HR" },
  { name: { GB: "Cuba", NL: "Cuba" }, dial_code: "+53", code: "CU" },
  { name: { GB: "Curaçao", NL: "Curaçao" }, dial_code: "+599", code: "CW" },
  { name: { GB: "Cyprus", NL: "Cyprus" }, dial_code: "+357", code: "CY" },
  {
    name: { GB: "Czech Republic", NL: "Tsjechië" },
    dial_code: "+420",
    code: "CZ"
  },
  { name: { GB: "Denmark", NL: "Denemarken" }, dial_code: "+45", code: "DK" },
  { name: { GB: "Djibouti", NL: "Djibouti" }, dial_code: "+253", code: "DJ" },
  { name: { GB: "Dominica", NL: "Dominica" }, dial_code: "+1767", code: "DM" },
  {
    name: { GB: "Dominican Republic", NL: "Dominicaanse Republiek" },
    dial_code: "+1849",
    code: "DO"
  },
  { name: { GB: "Ecuador", NL: "Ecuador" }, dial_code: "+593", code: "EC" },
  { name: { GB: "Egypt", NL: "Egypte" }, dial_code: "+20", code: "EG" },
  {
    name: { GB: "El Salvador", NL: "El Salvador" },
    dial_code: "+503",
    code: "SV"
  },
  {
    name: { GB: "Equatorial Guinea", NL: "Equatoriaal-Guinea" },
    dial_code: "+240",
    code: "GQ"
  },
  { name: { GB: "Eritrea", NL: "Eritrea" }, dial_code: "+291", code: "ER" },
  { name: { GB: "Estonia", NL: "Estland" }, dial_code: "+372", code: "EE" },
  { name: { GB: "Ethiopia", NL: "Ethiopië" }, dial_code: "+251", code: "ET" },
  {
    name: { GB: "Faroe Islands", NL: "Faeröer" },
    dial_code: "+298",
    code: "FO"
  },
  { name: { GB: "Fiji", NL: "Fiji" }, dial_code: "+679", code: "FJ" },
  { name: { GB: "Finland", NL: "Finland" }, dial_code: "+358", code: "FI" },
  { name: { GB: "France", NL: "Frankrijk" }, dial_code: "+33", code: "FR" },
  {
    name: { GB: "French Guiana", NL: "Frans Guyana" },
    dial_code: "+594",
    code: "GF"
  },
  {
    name: { GB: "French Polynesia", NL: "Frans-Polynesië" },
    dial_code: "+689",
    code: "PF"
  },
  { name: { GB: "Gabon", NL: "Gabon" }, dial_code: "+241", code: "GA" },
  { name: { GB: "Gambia", NL: "Gambia" }, dial_code: "+220", code: "GM" },
  { name: { GB: "Georgia", NL: "Georgië" }, dial_code: "+995", code: "GE" },
  { name: { GB: "Germany", NL: "Duitsland" }, dial_code: "+49", code: "DE" },
  { name: { GB: "Ghana", NL: "Ghana" }, dial_code: "+233", code: "GH" },
  { name: { GB: "Gibraltar", NL: "Gibraltar" }, dial_code: "+350", code: "GI" },

  { name: { GB: "Greece", NL: "Griekenland" }, dial_code: "+30", code: "GR" },
  { name: { GB: "Greenland", NL: "Groenland" }, dial_code: "+299", code: "GL" },
  { name: { GB: "Grenada", NL: "Grenada" }, dial_code: "+1473", code: "GD" },
  {
    name: { GB: "Guadeloupe", NL: "Guadeloupe" },
    dial_code: "+590",
    code: "GP"
  },
  { name: { GB: "Guam", NL: "Guam" }, dial_code: "+1671", code: "GU" },
  { name: { GB: "Guatemala", NL: "Guatemala" }, dial_code: "+502", code: "GT" },
  { name: { GB: "Guinea", NL: "Guinea" }, dial_code: "+224", code: "GN" },
  {
    name: { GB: "Guinea-Bissau", NL: "Guinee-Bissau" },
    dial_code: "+245",
    code: "GW"
  },
  { name: { GB: "Guyana", NL: "Guyana" }, dial_code: "+595", code: "GY" },
  { name: { GB: "Haiti", NL: "Haiti" }, dial_code: "+509", code: "HT" },
  { name: { GB: "Honduras", NL: "Honduras" }, dial_code: "+504", code: "HN" },
  { name: { GB: "Hungary", NL: "Hongarije" }, dial_code: "+36", code: "HU" },
  { name: { GB: "Iceland", NL: "IJsland" }, dial_code: "+354", code: "IS" },
  { name: { GB: "India", NL: "India" }, dial_code: "+91", code: "IN" },
  { name: { GB: "Indonesia", NL: "Indonesië" }, dial_code: "+62", code: "ID" },
  { name: { GB: "Iraq", NL: "Irak" }, dial_code: "+964", code: "IQ" },
  { name: { GB: "Ireland", NL: "Ierland" }, dial_code: "+353", code: "IE" },
  { name: { GB: "Israel", NL: "Israel" }, dial_code: "+972", code: "IL" },
  { name: { GB: "Italy", NL: "Italië" }, dial_code: "+39", code: "IT" },
  { name: { GB: "Jamaica", NL: "Jamaica" }, dial_code: "+1876", code: "JM" },
  { name: { GB: "Japan", NL: "Japan" }, dial_code: "+81", code: "JP" },
  { name: { GB: "Jordan", NL: "Jordanië" }, dial_code: "+962", code: "JO" },
  {
    name: { GB: "Kazakhstan", NL: "Kazachstan" },
    dial_code: "+77",
    code: "KZ"
  },
  { name: { GB: "Kenya", NL: "Kenia" }, dial_code: "+254", code: "KE" },
  { name: { GB: "Kiribati", NL: "Kiribati" }, dial_code: "+686", code: "KI" },
  { name: { GB: "Kuwait", NL: "Koeweit" }, dial_code: "+965", code: "KW" },
  { name: { GB: "Kyrgyzstan", NL: "Kirgizië" }, dial_code: "+996", code: "KG" },
  { name: { GB: "Latvia", NL: "Letland" }, dial_code: "+371", code: "LV" },
  { name: { GB: "Lebanon", NL: "Libanon" }, dial_code: "+961", code: "LB" },
  { name: { GB: "Lesotho", NL: "Lesotho" }, dial_code: "+266", code: "LS" },
  { name: { GB: "Liberia", NL: "Liberia" }, dial_code: "+231", code: "LR" },
  {
    name: { GB: "Liechtenstein", NL: "Liechtenstein" },
    dial_code: "+423",
    code: "LI"
  },
  { name: { GB: "Lithuania", NL: "Litouwen" }, dial_code: "+370", code: "LT" },
  {
    name: { GB: "Luxembourg", NL: "Luxemburg" },
    dial_code: "+352",
    code: "LU"
  },
  {
    name: { GB: "Madagascar", NL: "Madagascar" },
    dial_code: "+261",
    code: "MG"
  },
  { name: { GB: "Malawi", NL: "Malawi" }, dial_code: "+265", code: "MW" },
  { name: { GB: "Malaysia", NL: "Maleisië" }, dial_code: "+60", code: "MY" },
  { name: { GB: "Maldives", NL: "Maldiven" }, dial_code: "+960", code: "MV" },
  { name: { GB: "Mali", NL: "Mali" }, dial_code: "+223", code: "ML" },
  { name: { GB: "Malta", NL: "Malta" }, dial_code: "+356", code: "MT" },
  {
    name: { GB: "Marshall Islands", NL: "Marshall eilanden" },
    dial_code: "+692",
    code: "MH"
  },
  {
    name: { GB: "Martinique", NL: "Martinique" },
    dial_code: "+596",
    code: "MQ"
  },
  {
    name: { GB: "Mauritania", NL: "Mauritanië" },
    dial_code: "+222",
    code: "MR"
  },
  { name: { GB: "Mauritius", NL: "Mauritius" }, dial_code: "+230", code: "MU" },
  { name: { GB: "Mayotte", NL: "Mayotte" }, dial_code: "+262", code: "YT" },
  { name: { GB: "Mexico", NL: "Mexico" }, dial_code: "+52", code: "MX" },
  { name: { GB: "Monaco", NL: "Monaco" }, dial_code: "+377", code: "MC" },
  { name: { GB: "Mongolia", NL: "Mongolië" }, dial_code: "+976", code: "MN" },
  {
    name: { GB: "Montenegro", NL: "Montenegro" },
    dial_code: "+382",
    code: "ME"
  },
  {
    name: { GB: "Montserrat", NL: "Montserrat" },
    dial_code: "+1664",
    code: "MS"
  },
  { name: { GB: "Morocco", NL: "Marokko" }, dial_code: "+212", code: "MA" },
  { name: { GB: "Myanmar", NL: "Myanmar" }, dial_code: "+95", code: "MM" },
  { name: { GB: "Namibia", NL: "Namibië" }, dial_code: "+264", code: "NA" },
  { name: { GB: "Nauru", NL: "Nauru" }, dial_code: "+674", code: "NR" },
  { name: { GB: "Nepal", NL: "Nepal" }, dial_code: "+977", code: "NP" },
  {
    name: { GB: "Netherlands", NL: "Nederland" },
    dial_code: "+31",
    code: "NL"
  },
  {
    name: { GB: "Saint Maarten", NL: "Sint Maarten" },
    dial_code: "+1721",
    code: "SX"
  },
  {
    name: { GB: "New Zealand", NL: "Nieuw-Zeeland" },
    dial_code: "+64",
    code: "NZ"
  },
  { name: { GB: "Nicaragua", NL: "Nicaragua" }, dial_code: "+505", code: "NI" },
  { name: { GB: "Niger", NL: "Niger" }, dial_code: "+227", code: "NE" },
  { name: { GB: "Nigeria", NL: "Nigeria" }, dial_code: "+234", code: "NG" },
  { name: { GB: "Niue", NL: "Niue" }, dial_code: "+683", code: "NU" },
  {
    name: { GB: "Norfolk Island", NL: "Norfolkeiland" },
    dial_code: "+672",
    code: "NF"
  },
  {
    name: {
      GB: "Northern Mariana Islands",
      NL: "noordelijke Mariana eilanden"
    },
    dial_code: "+1670",
    code: "MP"
  },
  { name: { GB: "Norway", NL: "Noorwegen" }, dial_code: "+47", code: "NO" },
  { name: { GB: "Oman", NL: "Oman" }, dial_code: "+968", code: "OM" },
  { name: { GB: "Pakistan", NL: "Pakistan" }, dial_code: "+92", code: "PK" },
  { name: { GB: "Palau", NL: "Palau" }, dial_code: "+680", code: "PW" },
  { name: { GB: "Panama", NL: "Panama" }, dial_code: "+507", code: "PA" },
  {
    name: { GB: "Papua New Guinea", NL: "Papoea-Nieuw-Guinea" },
    dial_code: "+675",
    code: "PG"
  },
  { name: { GB: "Paraguay", NL: "Paraguay" }, dial_code: "+595", code: "PY" },
  { name: { GB: "Peru", NL: "Peru" }, dial_code: "+51", code: "PE" },
  {
    name: { GB: "Philippines", NL: "Filippijnen" },
    dial_code: "+63",
    code: "PH"
  },
  { name: { GB: "Poland", NL: "Polen" }, dial_code: "+48", code: "PL" },
  { name: { GB: "Portugal", NL: "Portugal" }, dial_code: "+351", code: "PT" },
  {
    name: { GB: "Puerto Rico", NL: "Puerto Rico" },
    dial_code: "+1939",
    code: "PR"
  },
  { name: { GB: "Qatar", NL: "Qatar" }, dial_code: "+974", code: "QA" },
  { name: { GB: "Romania", NL: "Roemenië" }, dial_code: "+40", code: "RO" },
  { name: { GB: "Rwanda", NL: "Rwanda" }, dial_code: "+250", code: "RW" },
  { name: { GB: "Samoa", NL: "Samoa" }, dial_code: "+685", code: "WS" },
  {
    name: { GB: "San Marino", NL: "San Marino" },
    dial_code: "+378",
    code: "SM"
  },
  {
    name: { GB: "Saba", NL: "Saba" },
    dial_code: "+599",
    code: "BQ"
  },
  {
    name: { GB: "Sint Eustatius", NL: "Sint Eustatius" },
    dial_code: "+599",
    code: "BQ"
  },
  {
    name: { GB: "New Caledonia", NL: "Nieuw-Caledonië" },
    dial_code: "+687",
    code: "NC"
  },
  {
    name: { GB: "Saudi Arabia", NL: "Saoedi-Arabië" },
    dial_code: "+966",
    code: "SA"
  },
  { name: { GB: "Senegal", NL: "Senegal" }, dial_code: "+221", code: "SN" },
  { name: { GB: "Serbia", NL: "Servië" }, dial_code: "+381", code: "RS" },
  {
    name: { GB: "Seychelles", NL: "Seychelles" },
    dial_code: "+248",
    code: "SC"
  },
  {
    name: { GB: "Sierra Leone", NL: "Sierra Leone" },
    dial_code: "+232",
    code: "SL"
  },
  { name: { GB: "Singapore", NL: "Singapore" }, dial_code: "+65", code: "SG" },
  { name: { GB: "Slovakia", NL: "Slowakije" }, dial_code: "+421", code: "SK" },
  { name: { GB: "Slovenia", NL: "Slovenië" }, dial_code: "+386", code: "SI" },
  {
    name: { GB: "Solomon Islands", NL: "Solomon eilanden" },
    dial_code: "+677",
    code: "SB"
  },
  {
    name: { GB: "South Africa", NL: "Zuid-Afrika" },
    dial_code: "+27",
    code: "ZA"
  },
  {
    name: {
      GB: "South Georgia and the South Sandwich Islands",
      NL: "Zuid-Georgia en de Zuidelijke Sandwicheilanden"
    },
    dial_code: "+500",
    code: "GS"
  },
  { name: { GB: "Spain", NL: "Spanje" }, dial_code: "+34", code: "ES" },
  { name: { GB: "Sri Lanka", NL: "Sri Lanka" }, dial_code: "+94", code: "LK" },
  { name: { GB: "Sudan", NL: "Soedan" }, dial_code: "+249", code: "SD" },
  { name: { GB: "Suriname", NL: "Suriname" }, dial_code: "+597", code: "SR" },
  { name: { GB: "Swaziland", NL: "Swaziland" }, dial_code: "+268", code: "SZ" },
  { name: { GB: "Sweden", NL: "Zweden" }, dial_code: "+46", code: "SE" },
  {
    name: { GB: "Switzerland", NL: "Zwitserland" },
    dial_code: "+41",
    code: "CH"
  },
  {
    name: { GB: "Tajikistan", NL: "Tadzjikistan" },
    dial_code: "+992",
    code: "TJ"
  },
  { name: { GB: "Thailand", NL: "Thailand" }, dial_code: "+66", code: "TH" },
  { name: { GB: "Togo", NL: "Togo" }, dial_code: "+228", code: "TG" },
  { name: { GB: "Tokelau", NL: "Tokelau" }, dial_code: "+690", code: "TK" },
  { name: { GB: "Tonga", NL: "Tonga" }, dial_code: "+676", code: "TO" },
  {
    name: { GB: "Trinidad and Tobago", NL: "Trinidad en Tobago" },
    dial_code: "+1868",
    code: "TT"
  },
  { name: { GB: "Tunisia", NL: "Tunesië" }, dial_code: "+216", code: "TN" },
  { name: { GB: "Turkey", NL: "Turkije" }, dial_code: "+90", code: "TR" },
  {
    name: { GB: "Turkmenistan", NL: "Turkmenistan" },
    dial_code: "+993",
    code: "TM"
  },
  {
    name: { GB: "Turks and Caicos Islands", NL: "Turks- en Caicoseilanden" },
    dial_code: "+1649",
    code: "TC"
  },
  { name: { GB: "Tuvalu", NL: "Tuvalu" }, dial_code: "+688", code: "TV" },
  { name: { GB: "Uganda", NL: "Oeganda" }, dial_code: "+256", code: "UG" },
  { name: { GB: "Ukraine", NL: "Oekraïne" }, dial_code: "+380", code: "UA" },
  {
    name: { GB: "United Arab Emirates", NL: "Verenigde Arabische Emiraten" },
    dial_code: "+971",
    code: "AE"
  },
  {
    name: { GB: "United Kingdom", NL: "Verenigd Koningkrijk" },
    dial_code: "+44",
    code: "GB"
  },
  {
    name: { GB: "United States", NL: "Verenigde Staten" },
    dial_code: "+1",
    code: "US"
  },
  { name: { GB: "Uruguay", NL: "Uruguay" }, dial_code: "+598", code: "UY" },
  {
    name: { GB: "Uzbekistan", NL: "Oezbekistan" },
    dial_code: "+998",
    code: "UZ"
  },
  { name: { GB: "Vanuatu", NL: "Vanuatu" }, dial_code: "+678", code: "VU" },
  {
    name: { GB: "Wallis and Futuna", NL: "Wallis en Futuna" },
    dial_code: "+681",
    code: "WF"
  },
  { name: { GB: "Yemen", NL: "Yemen" }, dial_code: "+967", code: "YE" },
  { name: { GB: "Zambia", NL: "Zambia" }, dial_code: "+260", code: "ZM" },
  { name: { GB: "Zimbabwe", NL: "Zimbabwe" }, dial_code: "+263", code: "ZW" },
  {
    name: { GB: "land Islands", NL: "land Eilanden" },
    dial_code: "",
    code: "AX"
  },

  {
    name: { GB: "Brunei Darussalam", NL: "Brunei Darussalam" },
    dial_code: "+673",
    code: "BN"
  },
  {
    name: { GB: "Cocos (Keeling) Islands", NL: "Cocos (Keeling) Eilanden" },
    dial_code: "+61",
    code: "CC"
  },

  { name: { GB: "Falkland", NL: "Falkland" }, dial_code: "+500", code: "FK" },
  { name: { GB: "Guernsey", NL: "Guernsey" }, dial_code: "+44", code: "GG" },
  { name: { GB: "Hong Kong", NL: "Hongkong" }, dial_code: "+852", code: "HK" },
  { name: { GB: "Iran", NL: "Iran" }, dial_code: "+98", code: "IR" },
  {
    name: { GB: "Isle of Man", NL: "Isle of Man" },
    dial_code: "+44",
    code: "IM"
  },
  { name: { GB: "Jersey", NL: "Jersey" }, dial_code: "+44", code: "JE" },
  {
    name: {
      GB: "Korea, Democratic People's Republic of",
      NL: "Korea, Democratische Volksrepubliek"
    },
    dial_code: "+850",
    code: "KP"
  },
  {
    name: { GB: "Korea, Republic of", NL: "Korea, republiek van" },
    dial_code: "+82",
    code: "KR"
  },
  {
    name: {
      GB: "Lao People's Democratic Republic",
      NL: "Lao Democratische Volksrepubliek"
    },
    dial_code: "+856",
    code: "LA"
  },
  {
    name: { GB: "Libyan Arab Jamahiriya", NL: "Libië" },
    dial_code: "+218",
    code: "LY"
  },
  { name: { GB: "Macao", NL: "Macau" }, dial_code: "+853", code: "MO" },
  {
    name: { GB: "Macedonia", NL: "Macedonië" },
    dial_code: "+389",
    code: "MK"
  },
  {
    name: {
      GB: "Micronesia, Federated States of",
      NL: "Micronesië, Federale Staten van"
    },
    dial_code: "+691",
    code: "FM"
  },
  { name: { GB: "Moldova", NL: "Moldavië" }, dial_code: "+373", code: "MD" },
  {
    name: { GB: "Mozambique", NL: "Mozambique" },
    dial_code: "+258",
    code: "MZ"
  },
  { name: { GB: "Pitcairn", NL: "Pitcairn" }, dial_code: "+872", code: "PN" },
  { name: { GB: "Réunion", NL: "Réunion" }, dial_code: "+262", code: "RE" },
  { name: { GB: "Russia", NL: "Rusland" }, dial_code: "+7", code: "RU" },
  {
    name: { GB: "Saint Barthélemy", NL: "Sint-Bartholomeus" },
    dial_code: "+590",
    code: "BL"
  },
  {
    name: {
      GB: "Saint Helena, Ascension and Tristan Da Cunha",
      NL: "Sint-Helena, Ascension en Tristan Da Cunha"
    },
    dial_code: "+290",
    code: "SH"
  },
  {
    name: { GB: "Saint Kitts and Nevis", NL: "Saint Kitts en Nevis" },
    dial_code: "+1869",
    code: "KN"
  },
  {
    name: { GB: "Saint Lucia", NL: "Saint Lucia" },
    dial_code: "+1758",
    code: "LC"
  },
  {
    name: { GB: "Saint Martin", NL: "Sint Maarten" },
    dial_code: "+590",
    code: "MF"
  },
  {
    name: { GB: "Saint Pierre and Miquelon", NL: "Saint Pierre en Miquelon" },
    dial_code: "+508",
    code: "PM"
  },
  {
    name: {
      GB: "Saint Vincent and the Grenadines",
      NL: "Saint Vincent en de Grenadines"
    },
    dial_code: "+1784",
    code: "VC"
  },
  {
    name: { GB: "Sao Tome and Principe", NL: "Sao Tome and Principe" },
    dial_code: "+239",
    code: "ST"
  },
  { name: { GB: "Somalia", NL: "Somalië" }, dial_code: "+252", code: "SO" },
  {
    name: { GB: "Svalbard and Jan Mayen", NL: "Svalbard en Jan Mayen" },
    dial_code: "+47",
    code: "SJ"
  },
  { name: { GB: "Syria", NL: "Syrië" }, dial_code: "+963", code: "SY" },
  { name: { GB: "Taiwan", NL: "Taiwan" }, dial_code: "+886", code: "TW" },
  { name: { GB: "Tanzania", NL: "Tanzania" }, dial_code: "+255", code: "TZ" },
  {
    name: { GB: "Timor-Leste", NL: "Oost-Timor" },
    dial_code: "+670",
    code: "TL"
  },
  { name: { GB: "Venezuela", NL: "Venezuela" }, dial_code: "+58", code: "VE" },
  { name: { GB: "Vietnam", NL: "Vietnam" }, dial_code: "+84", code: "VN" },
  {
    name: { GB: "Virgin Islands, British", NL: "Maagdeneilanden, Brits" },
    dial_code: "+1284",
    code: "VG"
  },
  {
    name: { GB: "Virgin Islands, U.S.", NL: "Maagdeneilanden, VS" },
    dial_code: "+1340",
    code: "VI"
  }
];
