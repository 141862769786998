import styled from "styled-components/macro";
import { theme } from "../../../styles/theme";

export const HelpNavbarItem = styled.section`
  font-weight: ${theme.font.weightSemiMedium};
  color: ${theme.colors.textDimmed};
  transition: 0.3s;
  line-height: 3.75;
  display: inline-flex;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.text};
  }
`;
