import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const ToggleDiv = styled.div`
  margin-bottom: 2px;
  background-color: ${theme.colors.white};
  border-radius: 12px;
  border: 1px solid ${theme.colors.textDimmed};
  padding: 8px;
  width: fit-content;
  align-self: center;

  @media (max-width: 425px) {
    align-self: flex-start;
  }
`;

export const ToggleTitle = styled.p<{ selected: boolean }>`
  width: fit-content;
  color: ${({ selected }) =>
    selected ? theme.colors.text : theme.colors.textDimmed};
  border: ${({ selected }) =>
    `1px solid ${selected ? theme.colors.textDimmed : "transparent"}`};
  border-radius: 6px;
  cursor: pointer;
  line-height: 40px;
  padding: 0 12px;
  font-weight: ${theme.font.weightMedium};
  background-color: ${({ selected }) =>
    selected ? theme.colors.place : "transparent"};

  &:hover {
    color: ${theme.colors.text};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > div:nth-child(1) {
    display: flex;
  }
  & ul li.coloured {
    color: ${theme.colors.text};
  }
  & p:not(:nth-last-child(1)) {
    margin-right: 1rem;
  }
`;
