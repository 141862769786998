import { useCallback, useEffect, useMemo, useState, useRef } from "react";

export const usePromisesLoader = () => {
  const [promises, setPromises] = useState<Promise<any>[]>([]);
  const _isMount = useRef(true);
  const isLoading = useMemo(() => promises.length > 0, [promises]);

  const watchPromise = useCallback((promise: Promise<any>) => {
    if (!promise) return;

    if (_isMount.current) {
      setPromises((promises) => [...promises, promise]);
    }

    promise
      .catch(() => {}) // this catch is needed to prevent raising unhanding error in this chain and throwing "Uncaught (in promise)" to console
      .finally(() => {
        if (_isMount.current) {
          setPromises((promises) => promises.filter((p) => p !== promise));
        }
      });
  }, []);

  useEffect(() => {
    _isMount.current = true;

    return () => {
      _isMount.current = false;
    };
  }, []);

  return {
    isLoading,
    watchPromise
  };
};
