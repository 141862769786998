import {
  ButtonSections,
  InformationSection,
  ListSection,
  PhotoSection,
  TitleH1,
  Wrapper
} from "./styles";
import { ToggleList } from "../ToggleList";
import { StyledButton } from "components/generic";
import { useSizeWindow } from "../../utils/hooks/useWindowSize";
import banner from "../../assets/images/Hospita en student samen op de bank.webp";
import student_banner from "../../assets/images/Hospita en student samen in de keuken.webp";
import host_banner from "../../assets/images/Hospes en student zitten buiten.webp";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { useContextHospi } from "context/ContextHospi";
import {
  HOST_ROOMS_ROUTE,
  HOST_WIZARD_ROUTE,
  STUDENT_ROOMS_ROUTE,
  STUDENT_WIZARD_ROUTE
} from "utils/constants";

interface IPreviewSection {
  mode: "hostPage" | "home" | "studentPage";
}

export const PreviewBlock: FC<IPreviewSection> = ({ mode }) => {
  const { width } = useSizeWindow();
  const [selectedTitle, setSelectedTitle] = useState<number>(0);

  const navigate = useNavigate();
  const { currentUserId, userProfile, cognitoUser } = useContextHospi();

  const [sharedBanner, translationFile] =
    mode === "home"
      ? [banner, "home"]
      : mode === "hostPage"
      ? [host_banner, "becomehost"]
      : [student_banner, "findroom"];
  const { t, i18n } = useTranslation(translationFile);
  const HomeHostsItems = (
    <ul>
      <li id="bold" className="coloured">
        {t("about.host.text1")}
      </li>
      <li id="bold" className="coloured">
        {t("about.host.text2")}
      </li>
      <li id="bold" className="coloured">
        {t("about.host.text3")}
      </li>
    </ul>
  );
  const HomeStudentItems = (
    <ul>
      <li id="bold" className="coloured">
        {t("about.student.text1")}
      </li>
      <li id="bold" className="coloured">
        {t("about.student.text2")}
      </li>
      <li id="bold" className="coloured">
        {t("about.student.text3")}
      </li>
    </ul>
  );

  const HostItems = (
    <ul>
      <li id="bold">{t("hostPage.banner.list.p1")}</li>
      <li id="bold">{t("hostPage.banner.list.p2")}</li>
      <li id="bold">{t("hostPage.banner.list.p3")}</li>
    </ul>
  );

  const StudentItems = (
    <ul>
      <li id="bold">{t("studentPage.banner.list.p1")}</li>
      <li id="bold">{t("studentPage.banner.list.p2")}</li>
      <li id="bold">{t("studentPage.banner.list.p3")}</li>
    </ul>
  );

  const HomeTitle = (
    <TitleH1>
      {t("titles.platformAbout.p1")} <span>{t("titles.platformAbout.p2")}</span>{" "}
      {t("titles.platformAbout.p3")} <span>{t("titles.platformAbout.p4")}</span>
    </TitleH1>
  );

  const HostTitle = (
    <TitleH1>
      {t("hostPage.banner.title.p1")}{" "}
      <span>{t("hostPage.banner.title.p2")}</span>
    </TitleH1>
  );

  const StudentTitle = (
    <TitleH1>
      {t("studentPage.banner.title.p1")}{" "}
      <span>{t("studentPage.banner.title.p2")}</span>{" "}
      {t("studentPage.banner.title.p3")}
    </TitleH1>
  );

  const Title =
    mode === "home"
      ? HomeTitle
      : mode === "hostPage"
      ? HostTitle
      : StudentTitle;

  // @ts-ignore
  return (
    <Wrapper>
      <div>
        <InformationSection>
          {Title}
          <ListSection>
            {mode === "home" ? (
              <ToggleList
                titles={[t("forHosts"), t("forStudents")]}
                content={[HomeHostsItems, HomeStudentItems]}
                setActive={setSelectedTitle}
              />
            ) : mode === "hostPage" ? (
              HostItems
            ) : (
              StudentItems
            )}
          </ListSection>
          <ButtonSections>
            <StyledButton
              p="16px 32px"
              onClick={() => {
                currentUserId && mode !== "home"
                  ? !userProfile
                    ? navigate(
                        generatePath(
                          cognitoUser?.role === "student"
                            ? STUDENT_WIZARD_ROUTE
                            : HOST_WIZARD_ROUTE,
                          {
                            lng: i18n.language
                          }
                        )
                      )
                    : navigate(
                        generatePath(
                          cognitoUser?.role === "host"
                            ? HOST_ROOMS_ROUTE
                            : STUDENT_ROOMS_ROUTE,
                          {
                            lng: i18n.language
                          }
                        )
                      )
                  : navigate(
                      `/${i18n.language}/register?m=${
                        mode === "hostPage"
                          ? "h"
                          : mode === "studentPage"
                          ? "s"
                          : selectedTitle === 0
                          ? "h"
                          : "s"
                      }`
                    );
              }}
            >
              {mode === "studentPage"
                ? t("buttons.signUpStudent", { ns: "home" })
                : mode === "hostPage"
                ? t("buttons.signUpHost", { ns: "home" })
                : t("buttons.signUp", { ns: "home" })}
            </StyledButton>
            {mode === "home" && (
              <StyledButton
                outline={true}
                p="16px 32px"
                onClick={() =>
                  navigate(
                    `/${i18n.language}/${
                      selectedTitle === 0 ? "hospita" : "students"
                    }`
                  )
                }
              >
                {selectedTitle === 0
                  ? t("buttons.moreAboutRentingOut")
                  : t("buttons.moreAboutRent")}
              </StyledButton>
            )}
          </ButtonSections>
        </InformationSection>
        {width >= 1050 && (
          <PhotoSection>
            <img fetchpriority="high" src={sharedBanner} alt="" />
          </PhotoSection>
        )}
      </div>
    </Wrapper>
  );
};
