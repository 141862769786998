import qs from "qs";
export const stringify = (
  obj: object,
  options?: { skipNulls?: boolean; [key: string]: any }
): string => {
  return qs.stringify(obj, { skipNulls: true, ...options });
};

export const parse = (str: string, options?: {}): object => {
  return qs.parse(str, options);
};

const api = {
  stringify,
  parse
};

export default api;
