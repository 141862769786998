import styled from "styled-components/macro";
import { theme } from "../../styles/theme";
import img from "assets/images/HP_solution_bg.webp";

export const Wrapper = styled.div`
  padding: 0 80px;
  @media (max-width: 930px) {
    padding: 0 40px;
  }
  @media (max-width: 850px) {
    padding: 0 20px;
  }
  @media (max-width: 425px) {
    padding: 0;
  }
  & > div {
    min-height: 400px;
    margin: 0 auto;
    border-radius: 12px;
    background-image: url(${img});
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1200px;
    padding: 46px 160px;
    display: flex;
    justify-content: space-between;
    & > div {
      justify-self: center;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      & button {
        margin: 0 auto;
      }
    }
    & p {
      text-align: center;
      font-size: 22px;
      color: ${theme.colors.text};
    }
    @media (max-width: 1175px) {
      padding: 46px 130px;
    }
    @media (max-width: 425px) {
      border-radius: 0;
      & h3,
      p {
        text-align: left;
      }
    }
    @media (max-width: 930px) {
      padding: 46px 40px;
    }
    @media (max-width: 850px) {
      padding: 46px 20px;
      & h3 {
        font-size: 32px;
      }
      & p {
        font-size: 20px;
      }
    }
    @media (max-width: 1056px) {
      flex-direction: column-reverse;
      align-items: center;
      & h3 {
        margin-top: 20px;
      }
    }
  }
`;

export const Title = styled.h3`
  font-size: 38px;
  margin-bottom: 8px;
  display: inline-block;
  text-align: center;
  line-height: 38px;
  color: ${theme.colors.text};
  & span {
    color: ${theme.colors.primary};
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 425px) {
    font-size: 25px;
  }
`;
