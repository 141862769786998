import { ISeo } from "interfaces/interfaces";
import { FC, memo } from "react";
import { Helmet } from "react-helmet";

export const HeaderSEO: FC<ISeo> = memo(
  ({
    title:
      title_txt = "Platform voor hospitaverhuur & kamerverhuur | Hospi Housing",
    keywords = "dvies en begeleiding bij het verhuren van een kamer in huis. Voor hosts, gastgezinnen en verhuurders geheel gratis.",
    description = "Hospitaverhuur, kamerverhuur, hospita, gastgezin, onderverhuur",
    canonicallink = "https://hospihousing.com/nl/",
    nllink = "https://hospihousing.com/nl/",
    gblink = "https://hospihousing.com/gb/"
  }) => {
    return (
      <Helmet>
        <title>{title_txt}</title>
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicallink} />

        <link rel="alternate" hrefLang="nl" href={nllink} />
        <link rel="alternate" hrefLang="en" href={gblink} />
        <link rel="alternate" hrefLang="x-default" href={canonicallink} />
      </Helmet>
    );
  }
);
