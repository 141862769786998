import { Wrapper } from "./styles";
import { StyledButton } from "../../components/generic";
import src from "assets/images/Screenshot website Hospi Housing.webp";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export const HelpStudent = () => {
  const { t, i18n } = useTranslation("home");

  return (
    <>
      <Wrapper>
        <div>
          <div>
            <p>
              {t("helpStudent.p1")} <b>{t("helpStudent.p2")}</b>{" "}
              {t("helpStudent.p3")}
            </p>
            <StyledButton p="16px 32px">
              <NavLink to={`/${i18n.language}/register`}>
                {t("buttons.signUp")}
              </NavLink>
            </StyledButton>
          </div>
          <div>
            <img src={src} alt="" loading="lazy" />
          </div>
        </div>
      </Wrapper>
      <div style={{ height: 64, background: "white" }} />
    </>
  );
};
