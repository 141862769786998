import {
  IStepValuePersonalInfo,
  IWizardHostState,
  TStepErrors,
  TStepPayload,
  TWizardStepComponentEditable,
  WIZARD_HOST_STEPS_PATHS
} from "../../../interfaces";
import { forwardRef, useImperativeHandle, useState } from "react";
import { WizardStepPersonalInfoView } from "../../../Student/Steps/WizardStepPersonalInfo";
import { useContextHospi } from "../../../../../context/ContextHospi";
import { useTranslation } from "react-i18next";
import { UserAPI } from "../../../../../API/userApi";
import { getChangedFields, getStepErrorsFromResponse } from "../../../utils";

const statePath = WIZARD_HOST_STEPS_PATHS.PersonalInfo;
export const WizardStepPersonalInfo: TWizardStepComponentEditable<IWizardHostState> =
  forwardRef(({ state, readonly, viewonly }, ref) => {
    const [values, setValues] = useState<TStepPayload<IStepValuePersonalInfo>>(
      state[statePath] ?? {}
    );
    const [errors, setErrors] =
      useState<TStepErrors<IStepValuePersonalInfo>>(null);

    const { currentUserRole, cognitoUser } = useContextHospi();
    const { i18n } = useTranslation(currentUserRole);

    useImperativeHandle(ref, () => ({
      save: () => {
        setErrors(null);
        return UserAPI.wizardHostSavePersonalInfo({
          ...values,
          default_language: i18n.language,
          user_type: currentUserRole
        })
          .then((result) => {
            window.dataLayer.push({
              event: "Host_signup_1.2_host_profile_filling",
              user_id: cognitoUser?.id,
              user_type: "host"
            });
            return {
              ...state,
              [statePath]: result
            };
          })
          .catch((e) => {
            setErrors(getStepErrorsFromResponse(e));
            return Promise.reject(e);
          });
      },
      savePartially: () => {
        setErrors(null);
        return UserAPI.wizardHostSavePersonalInfoPartially(
          getChangedFields(state[statePath], values)
        )
          .then((result) => {
            return {
              ...state,
              [statePath]: result
            };
          })
          .catch((e) => {
            setErrors(getStepErrorsFromResponse(e));
            return Promise.reject(e);
          });
      }
    }));

    return (
      <WizardStepPersonalInfoView
        values={values}
        setValues={setValues}
        errors={errors}
        readonly={readonly}
        viewonly={viewonly}
      />
    );
  });
