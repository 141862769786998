import { StrictMode, Suspense } from "react";
import { ErrorBoundary } from "@sentry/react";
import App from "./App";
import "./i18n";
import { ThemeProvider } from "styled-components/macro";
import GlobalStyle from "./styles/global";
import { theme } from "./styles/theme";
import { LoaderCustom } from "components/Loader";
import { createRoot } from "react-dom/client";
import { initAmplify } from "./config/amplify";
import { initSentry } from "./config/sentry";
import { GlobalErrorFallback } from "./components/GlobalErrorFallback";

// Amplify.Logger.LOG_LEVEL = "DEBUG";

initAmplify();
initSentry();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("isFatal", "true");
      }}
      fallback={({ error, componentStack }) => (
        <GlobalErrorFallback error={error} componentStack={componentStack} />
      )}
    >
      <Suspense fallback={<LoaderCustom size={5} text="" />}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Suspense>
      <GlobalStyle />
    </ErrorBoundary>
  </StrictMode>
);
