import { FC, CSSProperties } from "react";
import { Spin } from "antd";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import styled from "styled-components/macro";

export const LoadingIndicatorContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const LoadingIndicator: FC<{
  className?: string;
  fontSize?: number;
}> = ({ className, fontSize = 24 }) => {
  return (
    <Spin
      className={className}
      indicator={<LoadingOutlined style={{ fontSize }} spin />}
    />
  );
};

const LoaderLineRoot = styled.div`
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  border-radius: 20px;
  max-width: calc(100% - 16px);
  transform: translateX(8px);

  &:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: coral;
    animation: lineAnim 1s linear infinite;
    border-radius: 20px;
  }

  @keyframes lineAnim {
    0% {
      left: -40%;
    }
    50% {
      left: 20%;
      width: 80%;
    }
    100% {
      left: 100%;
      width: 100%;
    }
  }
`;

export const LineLoader: FC<{
  className?: string;
  style?: CSSProperties;
}> = ({ className, style }) => {
  return <LoaderLineRoot className={className} style={style}></LoaderLineRoot>;
};
