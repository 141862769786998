import styled from "styled-components/macro";
import { theme } from "../../styles/theme";
import { ToggleDiv } from "../ToggleList/styles";

export const Wrapper = styled.div`
  background: ${theme.colors.bgSecondary};

  & > div {
    margin: 0 auto;
    max-width: 1360px;
    padding: 80px 80px;
    display: flex;
    @media (max-width: 910px) {
      padding: 60px 40px 0 40px;
    }
    @media (max-width: 820px) {
      padding: 60px 20px 0 20px;
    }
  }
`;

export const InformationSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonSections = styled.div`
  display: flex;
  margin: 40px 0 80px 0;

  & button:first-child {
    margin-right: 12px;
  }
  @media (max-width: 465px) {
    flex-direction: column;
    & button:first-child {
      margin: 0 0 16px 0;
    }
  }
`;

export const PhotoSection = styled.div`
  & img {
    max-width: 520px;
  }
`;

export const TitleH1 = styled.h1`
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
  margin-bottom: 40px;
  color: ${(props) => props.theme.colors.text};

  & span {
    color: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: 768px) {
    font-size: 40px;
  }
  @media (max-width: 465px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const ListSection = styled.div`
  display: flex;

  ${ToggleDiv} {
    align-self: flex-start;
    margin-bottom: 1.5rem;
  }

  & ul li::before {
    content: "✔";
    left: -10px;
    font-weight: bold;

    font-size: 20px;
    margin-right: 10px;
  }

  & ul {
    list-style: none;
    margin: 0;

    & li {
      position: relative;
      font-size: 20px;
      color: ${(props) => props.theme.colors.textGray};
    }

    & li:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const ToggleTitle = styled.div<{ selected: boolean }>`
  width: fit-content;
  font-size: 20px;
  color: ${(props) =>
    props.selected ? props.theme.colors.text : props.theme.colors.textGray};
  border-bottom: 2px solid ${theme.colors.text};
`;
