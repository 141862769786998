import { Carousel as AntdCarousel } from "antd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Wrapper, ArrowNext, ArrowPrev, ItemList, Title } from "./styles";
import { listPartners2 } from "../../assets/data/infoPartners";
import { PictureLink } from "../../pages/rootpage/styles";
import { useTranslation } from "react-i18next";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export const Partners = () => {
  const [width] = useWindowSize();
  const [mediaSize, setMediaSize] = useState<number>(5);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const sliderRef = useRef<any>(null);
  const { t } = useTranslation("home");

  const next = () => {
    sliderRef.current.next();
  };

  const prev = () => {
    sliderRef.current.prev();
  };

  useEffect(() => {
    if (width <= 1075 && width > 830) {
      setMediaSize(4);
    } else if (width <= 830 && width > 768) {
      setMediaSize(3);
    } else if (width <= 768) {
      setMediaSize(2);
    } else {
      setMediaSize(5);
    }
  }, [width]);

  return (
    <Wrapper>
      <Title id="bold">{t("partners")}</Title>
      <div>
        {currentSlide !== 0 && <ArrowPrev onClick={prev} />}
        {currentSlide < listPartners2.length - mediaSize && (
          <ArrowNext onClick={next} />
        )}
        <AntdCarousel
          infinite={false}
          afterChange={(e) => setCurrentSlide(e)}
          dots={false}
          slidesToShow={mediaSize}
          ref={sliderRef}
        >
          {listPartners2.map((item, i) => (
            <ItemList url={item.logo} key={`partner${i}`}>
              <PictureLink
                logo={item.logo}
                rel="noreferrer"
                href={item.link}
                target="_blank"
                key={item.link}
              />
            </ItemList>
          ))}
        </AntdCarousel>
      </div>
    </Wrapper>
  );
};
