import { ListItem, ListWrapper, Title, Wrapper } from "./styles";
import { ToggleList } from "../ToggleList";
import hiw_1 from "assets/images/Daan Donkers aan het werk voor Hospi Housing.webp";
import hiw_s_1 from "assets/images/Screenshot van website Hospi Housing.webp";
import hiw_2 from "assets/images/Op de koffie bij een hospita.webp";
import hiw_s_2 from "assets/images/Student op bed in kamer.webp";
import hiw_3 from "assets/images/Screenshot van mobiele versie website hospi housing.webp";
import hiw_s_3 from "assets/images/Gastgezin voor hun huis in Utrecht.webp";
import { useTranslation } from "react-i18next";

export const HowItWorks = () => {
  const { t } = useTranslation("home");

  const hostState = [
    {
      src: hiw_1,
      text: t("howItWorks.host.p1.text"),
      title: t("howItWorks.host.p1.title"),
      alttext: t("howItWorks.host.p1.alttext")
    },
    {
      src: hiw_2,
      text: t("howItWorks.host.p2.text"),
      title: t("howItWorks.host.p2.title"),
      alttext: t("howItWorks.host.p2.alttext")
    },
    {
      src: hiw_3,
      text: t("howItWorks.host.p3.text"),
      title: t("howItWorks.host.p3.title"),
      alttext: t("howItWorks.host.p3.alttext")
    }
  ];

  const studentState = [
    {
      src: hiw_s_1,
      text: t("howItWorks.student.p1.text"),
      title: t("howItWorks.student.p1.title"),
      alttext: t("howItWorks.student.p1.alttext")
    },
    {
      src: hiw_s_2,
      text: t("howItWorks.student.p2.text"),
      title: t("howItWorks.student.p2.title"),
      alttext: t("howItWorks.student.p2.alttext")
    },
    {
      src: hiw_s_3,
      text: t("howItWorks.student.p3.text"),
      title: t("howItWorks.student.p3.title"),
      alttext: t("howItWorks.student.p3.alttext")
    }
  ];

  const render = (
    array: { src: string; title: string; text: string; alttext: string }[]
  ) => {
    return array.map(({ src, title, text, alttext }) => (
      <ListItem key={`ListItem${src}`}>
        <div>
          <img src={src} alt={alttext} loading="lazy" />
        </div>
        <div>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
      </ListItem>
    ));
  };

  return (
    <Wrapper>
      <div>
        <Title>
          {t("howItWorks.title.p1")} <span>{t("howItWorks.title.p2")}</span>
        </Title>
        <ListWrapper>
          <ToggleList
            titles={[t("forHosts"), t("forStudents")]}
            content={[render(hostState), render(studentState)]}
          />
        </ListWrapper>
      </div>
    </Wrapper>
  );
};
