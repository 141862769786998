function createNode(text: string) {
  const node = document.createElement("pre");
  node.style.width = "1px";
  node.style.height = "1px";
  node.style.position = "fixed";
  node.style.top = "5px";
  node.textContent = text;
  return node;
}

export async function copyNode(node: HTMLPreElement) {
  if ("clipboard" in navigator) {
    try {
      return await navigator.clipboard.writeText(node.textContent || "");
    } catch {
      // ignore and go next
    }
  }

  console.warn("copyNode using legacy execCommand()");

  const selection = window.getSelection();
  if (selection == null) {
    return Promise.reject(new Error());
  }

  selection.removeAllRanges();

  const range = document.createRange();
  range.selectNodeContents(node);
  selection.addRange(range);

  document.execCommand("copy");
  selection.removeAllRanges();
  return Promise.resolve();
}

export async function copyToClipboard(text: string) {
  if ("clipboard" in navigator) {
    try {
      return await navigator.clipboard.writeText(text);
    } catch {
      // ignore and go next
    }
  }

  const body = document.body;
  if (!body) {
    return Promise.reject(new Error());
  }

  const node = createNode(text);
  body.appendChild(node);
  await copyNode(node);
  body.removeChild(node);
  return Promise.resolve();
}
