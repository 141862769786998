import { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components/macro";
import { Button as AntdButton } from "antd";
import { ExclamationTriangle } from "@styled-icons/bootstrap/ExclamationTriangle";
import { Clock } from "@styled-icons/bootstrap/Clock";
import { Check2Circle } from "@styled-icons/bootstrap/Check2Circle";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";
import { Plus } from "@styled-icons/bootstrap/Plus";
import { Star } from "@styled-icons/bootstrap/Star";
import {
  MixinFloatElement,
  MixinFont,
  MixinPlace,
  ILabelProps,
  IPlaceProps
} from "./mixins";

interface ITitle {
  m?: string;
  fontSize?: string;
}

export const Title = styled.div<ITitle>`
  font-size: ${(props) => props.fontSize || "24px"};
  font-weight: 700;
  color: ${(props) => props.theme.colors.text};
  margin: ${(props) => props.m || "0"};
`;

export const SplitLine = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.textMuted};

  & > hr {
    ${MixinFloatElement};
    width: 100%;
    color: ${(props) => props.theme.colors.border};
  }

  & > label {
    position: relative;
    padding: 0 16px;
    background-color: ${(props) => props.theme.colors.bgBody};
  }
`;

export const Label = styled.label<ILabelProps & IPlaceProps>`
  padding: 0;
  ${MixinFont};
  ${MixinPlace};
`;

export const ErrorMessage = styled(Label)<{ error: boolean }>`
  color: transparent;
  ${(props) =>
    props.error &&
    css`
      color: red;
    `};
  font-weight: 400;
  font-size: 0.8em;
`;

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly main?: boolean;
  readonly normal?: boolean;
  readonly outline?: boolean;
  readonly danger?: boolean;
  readonly outlineSpecial?: boolean;
  readonly secondary?: boolean;
}

interface IStyledButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly outline?: boolean;
  readonly height?: number;
  readonly width?: number;
  readonly p?: string;
}

export const StyledButton = styled.button<
  IStyledButtonProps & ILabelProps & IPlaceProps
>`
  ${MixinFont};
  ${MixinPlace};
  cursor: pointer;
  background: ${(props) =>
    props.outline ? "transparent" : props.theme.colors.primary};
  height: ${(props) => (props.height ? `${props.height}px` : "")};
  width: ${(props) => (props.width ? `${props.width}px` : "")};
  padding: ${(props) => (props.p ? props.p : props.theme.sizes.input.padding)};
  color: ${(props) => (!props.outline ? "white" : props.theme.colors.primary)};
  border: ${(props) =>
    `1px solid ${props.outline ? props.theme.colors.primary : "transparent"}`};
  border-radius: 6px;
  font-size: ${({ fontSize }) => fontSize || "20px"};
  transition: 0.3s;
  font-weight: ${({ fontWeight }) => fontWeight || 600};

  &:hover {
    background: ${(props) => (props.outline ? "#FFF5EF" : "#D7520A")};
  }

  & a {
    color: ${(props) =>
      !props.outline ? "white" : props.theme.colors.primary};
  }

  &[disabled] {
    opacity: 60%;
  }
`;

export const Button = styled.button<IButtonProps & ILabelProps & IPlaceProps>`
  ${MixinFont};
  ${MixinPlace};
  cursor: pointer;
  padding: ${(props) => props.theme.sizes.input.padding};
  ${(props) =>
    props.main &&
    css`
      color: ${(props) => props.theme.colors.place};
      background-color: ${(props) => props.theme.colors.primary};
      border: 1px solid ${(props) => props.theme.colors.primary};

      &:hover {
        background-color: #cb5210;
      }
    `};
  ${(props) =>
    props.normal &&
    css`
      color: ${(props) => props.theme.colors.thirdly};
      background-color: ${(props) => props.theme.colors.secondary};
      border: 1px solid ${(props) => props.theme.colors.secondary};

      &:hover {
        background-color: #f0ddb9;
      }
    `};
  ${(props) =>
    props.outline &&
    css`
      color: ${(props) => props.theme.colors.thirdly};
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.colors.border};
    `};
  ${(props) =>
    props.outlineSpecial &&
    css`
      color: ${(props) => props.theme.colors.primary};
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.colors.primary};
    `};
  ${(props) =>
    props.danger &&
    css`
      color: ${(props) => props.theme.colors.place};
      background-color: #eb5757;
      border: 1px solid #eb5757;
    `};

  &:active:not(:disabled) {
    color: red;
  }

  &:hover:disabled {
    cursor: not-allowed;
  }

  &:hover {
    color: ${(props) => props.theme.colors.text};
  }

  &:disabled {
    opacity: 0.7;
  }

  align-self: flex-end;
`;

export const IcoInfo = styled(InfoCircle)<{ color?: "midGrey" }>`
  cursor: pointer;
  color: ${({ theme, color }) => theme.colors[color ?? "text"]};
  display: inline-block;
`;

export const IcoPlus = styled(Plus)<{ $color?: "hardGray" }>`
  color: ${({ theme, $color }) => theme.colors[$color || "thirdly"]};
`;

// For Host verify
export const IcoWarning = styled(ExclamationTriangle)`
  color: ${(props) => props.theme.colors.place};
  padding: 0 4px 2px 0;
`;

export const IcoClock = styled(Clock)`
  padding: 0 4px 2px 0;
`;

export const IcoDone = styled(Check2Circle)`
  padding: 0 4px 2px 0;
`;

export const IcoStar = styled(Star)`
  padding: 0 4px 2px 0;
`;

export const TitleH1 = styled.h1<{
  m?: string;
  maxWidth?: string;
  positionText?: "start" | "center" | "end";
  display?: string;
  alignItems?: string;
  fontSize?: "sizeBanner" | "size" | "sizeSubTitle" | "sizeMenu";
  lineHeight?: string;
  fontWeight?: "weightMedium" | "weightNormal" | "weightSemiMedium";
  color?: "hardGray";
}>`
  font-size: ${({ fontSize, theme }) =>
    fontSize?.length ? theme.font[fontSize] : theme.font.sizeTitle};
  width: 100%;
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight?.length ? theme.font[fontWeight] : theme.font.weightBold};
  align-items: ${(props) => props.alignItems};
  display: ${(props) => props.display};
  color: ${({ theme, color }) =>
    color?.length ? theme.colors[color] : theme.colors.text};
  margin: ${(props) => props.m || "0"};
  text-align: ${(props) => props.positionText || "start"};
  max-width: ${(props) => props.maxWidth || "initial"};

  ${({ lineHeight }) =>
    lineHeight?.length &&
    css`
      line-height: ${lineHeight};
    `}
`;

export const TitleH2 = styled.h2<{
  $margin?: string;
  $c?: "hardGray" | "midGrey" | "black";
  $fS?: "size" | "sizeTitle" | "sizeMedium" | "sizeMenu";
  $lH?: "defaultLineHeight";
  $fW?: "weightBold" | "weightNormal" | "weightSemiMedium";
}>`
  font-weight: ${({ theme, $fW }) =>
    $fW?.length ? theme.font[$fW] : theme.font.weightMedium};
  color: ${({ theme, $c }) =>
    $c?.length ? theme.colors[$c] : theme.colors.text};
  font-size: ${({ $fS, theme }) =>
    $fS?.length ? theme.font[$fS] : theme.font.sizeSubTitle};
  margin: ${({ $margin }) => $margin || 0};

  ${({ $lH, theme }) =>
    $lH?.length &&
    css`
      line-height: ${theme.text[$lH]};
    `}
`;

export const TextLink = styled.p`
  color: ${(props) => props.theme.colors.thirdly};
  font-weight: 600;
  cursor: pointer;
`;

export const TitleH3 = styled.h3<{
  m?: string;
  color?: "hardGray" | "midGrey";
  fontSize?:
    | "sizeBanner"
    | "sizeTitle"
    | "sizeSubTitle"
    | "sizeMenu"
    | "sizeMedium";
  lineHeight?: string;
  fW?: "weightBold" | "weightNormal" | "weightSemiMedium";
}>`
  color: ${({ theme, color }) =>
    color?.length ? theme.colors[color] : theme.colors.text};
  font-size: ${({ fontSize, theme }) =>
    fontSize?.length ? theme.font[fontSize] : theme.font.size};
  font-weight: ${({ theme, fW }) =>
    fW?.length ? theme.font[fW] : theme.font.weightMedium};
  margin: ${({ m }) => m || "0 0 4px"};

  ${({ lineHeight }) =>
    lineHeight?.length &&
    css`
      line-height: ${lineHeight};
    `}
`;

export const TitleH4 = styled.h4<{
  m?: string;
  fW?: "weightMedium" | "weightBold";
  c?: "hardGray" | "midGrey";
  lineHeight?: string;
  fS?: "sizeBanner" | "sizeTitle" | "sizeSubTitle" | "sizeMenu" | "sizeMedium";
}>`
  color: ${({ theme, c }) => (c?.length ? theme.colors[c] : theme.colors.text)};
  font-size: ${({ fS, theme }) =>
    fS?.length ? theme.font[fS] : theme.font.size};
  font-weight: ${({ theme, fW }) =>
    fW?.length ? theme.font[fW] : theme.font.weightNormal};
  margin: ${(props) => props.m || "0"};

  ${({ lineHeight }) =>
    lineHeight?.length &&
    css`
      line-height: ${lineHeight};
    `}
`;

export const TitleH14 = styled.h4<{
  m?: string;
  color?: "midGrey";
  fontWeight?: "weightBold" | "weightNormal";
}>`
  color: ${({ theme, color }) =>
    color?.length ? theme.colors[color] : theme.colors.thirdly};
  font-size: 14px;
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight?.length ? theme.font[fontWeight] : theme.font.weightMedium};
  margin: ${(props) => props.m || "0"};
`;

export const Text18Size = styled.p`
  font-size: 18px;
  line-height: 133%;
  color: ${(props) => props.theme.colors.text};
`;

export const TextMessage = styled.p`
  font-size: 18px;
  line-height: 133%;
  word-break: break-word;
  white-space: normal;
  color: ${(props) => props.theme.colors.text};
  @media ${(props) => props.theme.media.phone} {
    font-size: 14px;
  }
`;

export const TextPlain = styled.p<{
  margin?: string;
  lineHeight?: string;
  color?: "hardGray";
  fW?: "weightBold" | "weightSemiMedium" | "weightMedium";
  fS?: "sizeTitle" | "sizeSubTitle" | "sizeMedium";
}>`
  font-size: ${({ fS, theme }) =>
    fS?.length ? theme.font[fS] : theme.font.size};
  word-break: break-all;
  white-space: normal;
  margin: ${({ margin }) => margin || 0};
  color: ${({ theme, color }) =>
    color?.length ? theme.colors[color] : theme.colors.text};

  ${({ lineHeight }) =>
    lineHeight?.length &&
    css`
      line-height: ${lineHeight};
    `}

  ${({ fW }) =>
    fW?.length &&
    css`
      font-weight: ${fW};
    `}

  @media ${(props) => props.theme.media.phone} {
    font-size: 14px;
  }
`;

export const HorizontalDivider = styled.hr<{
  margin?: string;
  color?: "bgSoftGray" | "textDimmed";
}>`
  height: 1px;
  width: 100%;
  background-color: ${({ theme, color }) =>
    theme.colors[color ?? "borderSecondary"]};
  margin: ${({ margin }) => margin || 0};
  border: 0 none transparent;
`;

export const Splitter = styled.div<{ $bgc?: "bgBody" }>`
  position: relative;
  text-align: center;
  width: 100%;

  & > label {
    position: absolute;
    padding: 0 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme, $bgc }) =>
      $bgc?.length ? theme.colors[$bgc] : theme.colors.white};
    color: ${({ theme }) => theme.colors.textMuted};
    font-weight: ${({ theme }) => theme.font.weightMedium};
  }
`;

export const StickyFooterWrapper = styled.div`
  position: sticky;
  bottom: ${({ theme }) => theme.sizes.mobileStickyNavbarHeight};
  left: 0;
  right: 0;
  height: ${({ theme }) => theme.sizes.stickyFooterHeight};
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

export const DownloadButton = styled(AntdButton)`
  &.ant-btn.ant-btn-text,
  &.ant-btn.ant-btn-link {
    color: ${({ theme }) => theme.colors.text};
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 8px;
    gap: 8px;
    font-weight: ${({ theme }) => theme.font.weightMedium};
    border-color: ${({ theme }) => theme.colors.borderSecondary};
    background-color: ${({ theme }) => theme.colors.place};
    font-size: ${({ theme }) => theme.font.size};
    line-height: 1.5;
    padding-left: 16px;
    padding-right: 16px;

    svg,
    span {
      display: inline-block;
    }
    path {
      stroke: ${({ theme }) => theme.colors.text};
    }
    &:focus-visible {
      outline: 0 none transparent;
      outline-offset: 0;
    }
    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.text};
      background-color: ${({ theme }) => theme.colors.place};
      border-color: ${({ theme }) => theme.colors.borderSecondary};
    }
  }
`;

export const DeleteButton = styled(AntdButton)`
  &.ant-btn.ant-btn-default {
    width: fit-content;
    color: ${({ theme }) => theme.colors.error};
    border-color: ${({ theme }) => theme.colors.error};
    box-shadow: none;
    font-weight: ${({ theme }) => theme.font.weightMedium};
    padding: 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.fullWidth {
      width: 100%;
    }
    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.error};
      border-color: ${({ theme }) => theme.colors.error};
    }
    &:focus-visible {
      outline: 0 none transparent;
      outline-offset: 0;
    }

    @media ${({ theme }) => theme.media.tablet} {
      width: 100%;
    }
    @media ${({ theme }) => theme.media.phone} {
      width: 100%;
    }
  }
`;

export const HighlightedText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

// STYLEGUIDE:
// Buttons
export const PrimaryButton = styled(AntdButton)`
  &.ant-btn.ant-btn-primary {
    font-weight: ${({ theme }) => theme.font.weightMedium};
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 8px;
    padding-left: 1em;
    padding-right: 1em;
  }
`;
export const SecondaryButton = styled(AntdButton)`
  &.ant-btn.ant-btn-default {
    color: ${({ theme }) => theme.colors.hardGray};
    border-color: ${({ theme }) => theme.colors.midGrey};
    font-weight: ${({ theme }) => theme.font.weightMedium};
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 8px;
    padding-left: 1em;
    padding-right: 1em;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.hardGray};
      border-color: ${({ theme }) => theme.colors.midGrey};
    }
    &:disabled {
      border-color: ${({ theme }) => theme.colors.borderSecondary2};
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;
// Text content
export const PageTitleH1 = styled.h1<{ $lineHeight?: string }>`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.font.weightBold};
  color: ${({ theme }) => theme.colors.hardGray};
  line-height: ${({ theme, $lineHeight }) =>
    $lineHeight ?? theme.text.defaultLineHeight};
`;
export const SubtitleH2 = styled.h2<{ $color?: "text" }>`
  font-size: 1.125rem; // 18px
  font-weight: ${({ theme }) => theme.font.weightMedium};
  color: ${({ theme, $color }) => theme.colors[$color ?? "hardGray"]};
  line-height: ${({ theme }) => theme.text.defaultLineHeight};
`;
export const CardTitleH3 = styled.h3`
  font-size: 1.125rem; // 18px
  font-weight: ${({ theme }) => theme.font.weightMedium};
  color: ${({ theme }) => theme.colors.hardGray};
  line-height: 1.5;
`;
export const LabelOrStatus = styled.p<{
  $color?: "text" | "textMuted";
  $bgColor?: "bgSecondary";
  $borderRadius?: string;
  $padding?: string;
  $lineHeight?: string;
}>`
  font-size: 0.875rem; // 14px
  font-weight: ${({ theme }) => theme.font.weightMedium};
  color: ${({ theme, $color }) => theme.colors[$color ?? "hardGray"]};
  line-height: ${({ theme, $lineHeight }) =>
    $lineHeight ?? theme.text.defaultLineHeight};
  background: ${({ theme, $bgColor }) =>
    $bgColor ? theme.colors[$bgColor] : "transparent"};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? 0};
  padding: ${({ $padding }) => $padding ?? 0};
`;
// Popup content
export const PopupTitleH2 = styled.h2`
  font-size: 1.25rem; // 20px
  font-weight: ${({ theme }) => theme.font.weightMedium};
  color: ${({ theme }) => theme.colors.hardGray};
  line-height: 1.5;
`;
