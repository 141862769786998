import { createContext, FC, ReactNode, useContext, useState } from "react";
import omit from "lodash/omit";

// key string is contract id:
type TSignatures = { [key: string]: string | undefined };

interface IContextContractsSignatures {
  signatures: TSignatures;
  updateSignature: (contractId: string, signature: string) => void;
  removeSignature: (contractId?: string) => void;
}

// Currently the context is used only for host signatures:
const ContextContractsSignatures = createContext<IContextContractsSignatures>({
  signatures: {},
  updateSignature: (_, __) => {},
  removeSignature: (_) => {}
});

export const useContextContractsSignatures =
  (): IContextContractsSignatures => {
    return useContext<IContextContractsSignatures>(ContextContractsSignatures);
  };

export const ContractsSignaturesProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [signatures, setSignature] = useState<TSignatures>({});

  // signatureValue string is base64string of type image/png:
  const updateSignature = (contractId: string, signatureValue: string) => {
    setSignature((prevSignatures) => {
      return {
        ...prevSignatures,
        [contractId]: signatureValue
      };
    });
  };
  const removeSignature = (contractId?: string) => {
    if (typeof contractId === "string" && !!signatures[contractId]) {
      setSignature((prevSignatures) => omit(prevSignatures, contractId));
    }
  };

  return (
    <ContextContractsSignatures.Provider
      value={{
        signatures,
        updateSignature,
        removeSignature
      }}
    >
      {children}
    </ContextContractsSignatures.Provider>
  );
};
