import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HouseAPI } from "../houseApi";
import {
  IWizardHouseStateShort,
  TWizardHouseType
} from "../../components/Wizard/interfaces";
import {
  IHouse,
  IHouseRepublishPayload,
  IHouseWithRentPeriod,
  IResponseShort,
  THouseTogglePublishPayload
} from "../../interfaces/interfaces";
import queryClient from "../../stores/queryClient";
import { useContextHospi } from "../../context/ContextHospi";
import { sortHouses } from "../../utils/helpers";

export const getHouseQueryKey = (id?: string) => ["house", id];

export const useGetHouse = (id?: string) => {
  return useQuery(getHouseQueryKey(id), () => HouseAPI.getHouse(id!), {
    enabled: Boolean(id)
  });
};

export const useUpdateHouse = () => {
  const queryClient = useQueryClient();
  const { cognitoUser } = useContextHospi();

  return useMutation<
    IHouseWithRentPeriod,
    unknown,
    { id: string; body: Partial<IHouse> }
  >({
    mutationFn: (payload) => HouseAPI.updateHouse(payload.id, payload.body),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getHouseQueryKey(variables.id)
      });
      queryClient.invalidateQueries({
        queryKey: getHousesQueryKey(cognitoUser?.id!)
      });
    }
  });
};
export const useRepublishHouse = () => {
  const queryClient = useQueryClient();
  const { cognitoUser } = useContextHospi();

  return useMutation<
    IHouseWithRentPeriod,
    unknown,
    { id: string; body: IHouseRepublishPayload }
  >({
    mutationFn: (payload) => HouseAPI.republishHouse(payload.id, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getHousesQueryKey(cognitoUser?.id!)
      });
    }
  });
};

// export const useUploadHousePhoto = () => {
//   const queryClient = useQueryClient();
//   return useMutation<
//     THousePhoto,
//     unknown,
//     { id: string; body: File | string; timestamp: number; order: number }
//   >({
//     mutationFn: (payload) =>
//       HouseAPI.uploadPhoto(
//         payload.id,
//         payload.body,
//         payload.timestamp,
//         payload.order
//       ),
//     onSuccess: (_, variables) => {
//       queryClient.invalidateQueries({
//         queryKey: getHouseQueryKey(variables.id)
//       });
//     }
//   });
// };

// export const useDeleteHousePhotos = () => {
//   const queryClient = useQueryClient();
//   return useMutation<
//     THousePhoto,
//     unknown,
//     {
//       id: string;
//       fileNames: string[];
//     }
//   >({
//     mutationFn: (payload) =>
//       HouseAPI.deletePhotos(payload.id, payload.fileNames),
//     onSuccess: (_, variables) => {
//       queryClient.invalidateQueries({
//         queryKey: getHouseQueryKey(variables.id)
//       });
//     }
//   });
// };

// export const useReorderHousePhotos = () => {
//   const queryClient = useQueryClient();
//   return useMutation<
//     THousePhoto,
//     unknown,
//     {
//       id: string;
//       order: { file_name: string; order: number }[];
//     }
//   >({
//     mutationFn: (payload) => HouseAPI.reorderPhotos(payload.id, payload.order),
//     onSuccess: (_, variables) => {
//       queryClient.invalidateQueries({
//         queryKey: getHouseQueryKey(variables.id)
//       });
//     }
//   });
// };

export const getHousesQueryKey = (id?: string) => ["houses", id];

export const useGetHouses = (userId?: string) => {
  return useQuery(
    getHousesQueryKey(userId),
    () => {
      if (!userId) {
        return;
      }
      return HouseAPI.getHouses(userId).then((houses) =>
        houses.sort(sortHouses)
      );
    },
    {
      enabled: Boolean(userId)
    }
  );
};

export const useGetHostHouses = () => {
  const { cognitoUser } = useContextHospi();

  return useGetHouses(
    cognitoUser?.role === "host" ? cognitoUser.id : undefined
  );
};

export const useTogglePublishing = () => {
  const queryClient = useQueryClient();
  const { cognitoUser } = useContextHospi();

  return useMutation<
    IHouseWithRentPeriod,
    unknown,
    { id: string; isPublishing: boolean; body?: THouseTogglePublishPayload }
  >({
    mutationFn: ({ id, isPublishing, body }) =>
      isPublishing
        ? HouseAPI.publishHouse(id)
        : HouseAPI.depublishHouse(id, body!),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getHouseQueryKey(variables.id)
      });
      queryClient.invalidateQueries({
        queryKey: getHousesQueryKey(cognitoUser?.id!)
      });
    }
  });
};

export const useDeleteHouse = () => {
  const queryClient = useQueryClient();
  const { cognitoUser } = useContextHospi();

  return useMutation<any, unknown, { id: string }>({
    mutationFn: (payload) => HouseAPI.deleteHouse(payload.id),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getHouseQueryKey(variables.id)
      });
      queryClient.invalidateQueries({
        queryKey: getHousesQueryKey(cognitoUser?.id!)
      });
    }
  });
};

export const invalidateHouses = (userId: string) => {
  queryClient.invalidateQueries({
    queryKey: getHousesQueryKey(userId)
  });
};

export const getWizardsStateShortQueryKey = () => ["useGetWizardsStateShort"];
export const useGetWizardsStateShort = (enabled: boolean = true) => {
  return useQuery(
    getWizardsStateShortQueryKey(),
    () => {
      return HouseAPI.getWizardsStateShort();
    },
    {
      enabled: enabled
    }
  );
};

export const invalidateWizardsStateShort = () => {
  queryClient.invalidateQueries({
    queryKey: getWizardsStateShortQueryKey()
  });
};

export const useCreateWizard = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IWizardHouseStateShort,
    unknown,
    { type: TWizardHouseType }
  >({
    mutationFn: (payload) => HouseAPI.createWizard(payload.type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getWizardsStateShortQueryKey()
      });
    }
  });
};

export const useDeleteWizard = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IResponseShort,
    unknown,
    { houseId: string; type: TWizardHouseType }
  >({
    mutationFn: (payload) =>
      HouseAPI.deleteWizard(payload.houseId, payload.type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getWizardsStateShortQueryKey()
      });
    }
  });
};

export const useChangeLiked = () => {
  return useMutation<
    unknown,
    unknown,
    { isLiked: boolean; studentId: string; hostId: string; houseId: string }
  >({
    mutationFn: (payload) =>
      HouseAPI.changeLiked(
        payload.isLiked,
        payload.studentId,
        payload.hostId,
        payload.houseId
      )
  });
};
