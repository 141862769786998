import { FC, useRef, useState } from "react";
import useHover from "../../utils/hooks/useHover";
import useOnClickOutside from "../../utils/hooks/useOnClickOutside";
import { useTranslation } from "react-i18next";
import { BubbleCont, MainCont, SelectCont, SvgBubble } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { GB, NL } from "../Icons/CircleFlags";
import { ArrowDown } from "../Header/styles";

const LANGUAGES_SHORT: { [id: string]: string } = {
  nl: "NL",
  gb: "EN"
};
const LANGUAGES_BUBBLES: { [id: string]: JSX.Element } = {
  nl: NL,
  gb: GB
};

export const LangBubble: FC = () => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const { t, i18n } = useTranslation("header");
  const navigate = useNavigate();
  const location = useLocation();
  const language = i18n.language as "gb" | "nl";

  // Have to redefine this here since it is only defined in the footer. TO-DO a const somewhere.
  const LANGUAGES_SELECT = [
    {
      label: t("menu.gb"),
      value: "gb"
    },
    {
      label: t("menu.nl"),
      value: "nl"
    }
  ];

  const changeLanguage = (flag: "gb" | "nl"): void => {
    navigate(
      {
        pathname: `/${flag.toLowerCase()}/${location.pathname.slice(4)}${
          location.search
        }`
      },
      { replace: true }
    );
    i18n.changeLanguage(flag.toLocaleLowerCase());
  };

  useOnClickOutside(ref, () => {
    !isHovered && setShow(false);
  });

  return (
    <BubbleCont>
      <MainCont
        onClick={() => {
          setShow(!show);
        }}
        ref={hoverRef}
      >
        <SvgBubble>{LANGUAGES_BUBBLES[language]}</SvgBubble>
        <p>{LANGUAGES_SHORT[language]}</p>
        <ArrowDown size="2em" up={show} className={"arrow"} />
      </MainCont>
      {show && (
        <SelectCont ref={ref}>
          {LANGUAGES_SELECT.map((item, index) => (
            <li
              key={`lang${item.value}${index}`}
              onClick={() => {
                changeLanguage(item.value as "gb" | "nl");
                setShow(false);
              }}
            >
              {item.label}
            </li>
          ))}
        </SelectCont>
      )}
    </BubbleCont>
  );
};
