import { FC, useState } from "react";
import { Button } from "antd";
import { copyToClipboard } from "../../utils/copyToClipboard";
import { ButtonsContainer, Inner, Root, StyledError } from "./styles";

type TGlobalErrorFallback = {
  error: Error;
  componentStack: string;
  children?: never;
};

export const GlobalErrorFallback: FC<TGlobalErrorFallback> = ({
  error,
  componentStack
}) => {
  const [expandError, setExpandError] = useState(false);
  const errorText =
    error.message +
    "\n" +
    (error.stack ? error.stack + "\n" : "") +
    componentStack;

  return (
    <Root>
      {/* Set text without translation cause l18n service could not be available at the moment */}
      <Inner>
        Oops, there is a problem with your browser or our app. Don't worry! Try
        opening you browser in incognito mode and logging in again. If the
        problem persists, please get in touch with us via contact{" "}
        <a href="https://hospihousing.com/gb/contact">form</a>.
      </Inner>
      <ButtonsContainer>
        <Button
          onClick={() => {
            copyToClipboard(errorText);
          }}
          size="large"
          type="primary"
        >
          Copy error details
        </Button>
        <Button
          onClick={() => {
            setExpandError((prev) => !prev);
          }}
          size="large"
        >
          {expandError ? "Hide error details" : "Show error details"}
        </Button>
      </ButtonsContainer>
      {expandError ? <StyledError>{errorText}</StyledError> : null}
    </Root>
  );
};
