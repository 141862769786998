import nLogo1 from "assets/images/partnerLogos/LogoGemeenteUtrecht.webp";
import nLogo2 from "assets/images/partnerLogos/LogoGemeenteLeiden.webp";
import nLogo3 from "assets/images/partnerLogos/LogoESNInternationalStudentNetwork.webp";
import nLogo4 from "assets/images/partnerLogos/LogoGemeenteGroningen.webp";
import nLogo5 from "assets/images/partnerLogos/LogoGemeenteMaastricht.webp";
import nLogo6 from "assets/images/partnerLogos/LogoUniversiteitMaastricht.webp";
import nLogo7 from "assets/images/partnerLogos/LogoVrijeUniversiteitAmsterdam.webp";
import nLogo8 from "assets/images/partnerLogos/LogoUniversiteitUtrecht.webp";
import nLogo9 from "assets/images/partnerLogos/LogoHogeschoolUtrecht.webp";
import nLogo10 from "assets/images/partnerLogos/LogoHKUUtrecht.webp";
import nLogo11 from "assets/images/partnerLogos/LogoRijksuniversiteitRUGGroningen.webp";
import nLogo12 from "assets/images/partnerLogos/LogoHanzehogeschoolGroningen.webp";
import nLogo13 from "assets/images/partnerLogos/LogoUniversiteitLeiden.webp";
import nLogo14 from "assets/images/partnerLogos/LogoHogeschoolLeiden.webp";
import nLogo15 from "assets/images/partnerLogos/LogoACCESSinternationalCommunity.webp";
import nLogo16 from "assets/images/partnerLogos/LogoKamersMetAandacht.webp";
import nLogo17 from "assets/images/partnerLogos/LogoVidiusStudentenunie.webp";
import nLogo18 from "assets/images/partnerLogos/LogoBuddyGoDutch.webp";
import nLogo19 from "assets/images/partnerLogos/LogoKlup.webp";
import nLogo20 from "assets/images/partnerLogos/LogoMatchMaastricht.webp";

export interface ILogoLink {
  link: string;
  logo: string;
}

export let listPartners2: ILogoLink[] = [
  {
    link: "https://www.utrecht.nl/",
    logo: nLogo1
  },
  {
    link: "https://gemeente.leiden.nl/inwoners-en-ondernemers/wonen-en-bouwen/wonen/kamer-en-hospitaverhuur/",
    logo: nLogo2
  },
  {
    link: "https://esn-utrecht.nl/",
    logo: nLogo3
  },
  {
    link: "https://gemeente.groningen.nl/",
    logo: nLogo4
  },
  {
    link: "https://www.gemeentemaastricht.nl/",
    logo: nLogo5
  },
  {
    link: "https://www.maastrichtuniversity.nl/",
    logo: nLogo6
  },
  {
    link: "https://vu.nl/nl",
    logo: nLogo7
  },
  {
    link: "https://www.uu.nl/",
    logo: nLogo8
  },
  {
    link: "https://www.hu.nl/",
    logo: nLogo9
  },
  {
    link: "https://www.hku.nl/",
    logo: nLogo10
  },
  {
    link: "https://www.rug.nl/",
    logo: nLogo11
  },
  {
    link: "https://www.hanze.nl/",
    logo: nLogo12
  },
  {
    link: "https://www.universiteitleiden.nl/",
    logo: nLogo13
  },
  {
    link: "https://www.hsleiden.nl/",
    logo: nLogo14
  },
  {
    link: "https://access-nl.org/",
    logo: nLogo15
  },
  {
    link: "https://kamersmetaandacht.nl/",
    logo: nLogo16
  },
  {
    link: "https://vidius.nl/en/",
    logo: nLogo17
  },
  {
    link: "https://buddygodutch.nl/",
    logo: nLogo18
  },
  {
    link: "https://www.kluppen.nl/",
    logo: nLogo19
  },
  {
    link: "https://matchmaastricht.nl/projects/homesharing/",
    logo: nLogo20
  }
];
