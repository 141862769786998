import styled from "styled-components/macro";
import { theme } from "../../styles/theme";
import { ToggleDiv } from "../../landing-components/ToggleList/styles";

export const Wrapper = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  padding-bottom: 64px;
  & a {
    z-index: 1;
  }
  & > div {
    padding: 0 124.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 910px) {
      padding: 0 40px;
    }
    @media (max-width: 820px) {
      padding: 0 20px;
    }
  }
  ${ToggleDiv} {
    margin-bottom: 7px;
    @media (max-width: 425px) {
      align-self: center;
    }
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 40px;
  margin: 64px 0 24px 0;
  color: ${theme.colors.text};
  text-align: center;
  & span {
    color: ${theme.colors.primary};
  }
  @media (max-width: 475px) {
    font-size: 32px;
  }
  @media (max-width: 375px) {
    font-size: 28px;
  }
`;
