import { Title, Wrapper } from "./styles";
import { Accordion } from "../../landing-components/Accordion";
import { ToggleList } from "../../landing-components/ToggleList";
import { StyledButton } from "../generic";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { STUDENT_TIPS_ROUTE } from "utils/constants";
import { useState } from "react";
export const FAQ = () => {
  const { t, i18n } = useTranslation("home");
  const location = useLocation();

  const pageKey = location.pathname.includes("hospita")
    ? "host"
    : location.pathname.includes("students")
    ? "student"
    : "home";

  const studentQuestions = new Array(6).fill("").map((_, i) => ({
    title: t(`faq.student.p${i + 1}.title`),
    content: t(`faq.student.p${i + 1}.content`)
  }));
  const hostQuestions = new Array(7).fill("").map((_, i) => ({
    title: t(`faq.host.p${i + 1}.title`),
    content: t(`faq.host.p${i + 1}.content`)
  }));

  const sharedQuestions = pageKey === "host" ? hostQuestions : studentQuestions;

  const hostAccordion = <Accordion questions={hostQuestions} />;
  const studentAccordion = <Accordion questions={studentQuestions} />;
  const [selectedToggleIndex, setSelectedToggleIndex] = useState(0);

  return (
    <Wrapper>
      <Title>
        {t("faq.title.p1")} <span>{t("faq.title.p2")}</span>
      </Title>
      {pageKey === "home" ? (
        <ToggleList
          titles={[t("forHosts"), t("forStudents")]}
          content={[hostAccordion, studentAccordion]}
          setActive={setSelectedToggleIndex}
        />
      ) : (
        <div>
          <Accordion questions={sharedQuestions} />
        </div>
      )}

      <div style={{ margin: "0 auto" }}>
        <NavLink
          to={
            pageKey === "student" ||
            (pageKey === "home" && selectedToggleIndex === 1)
              ? `${STUDENT_TIPS_ROUTE}`
              : `/${i18n.language}/faq`
          }
        >
          <StyledButton outline={true} p="16px 32px">
            {t("buttons.allFaq")}
          </StyledButton>
        </NavLink>
      </div>
    </Wrapper>
  );
};
