import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div<{ open: boolean }>`
  z-index: 999;
  position: absolute;
  bottom: 25px;
  left: 25px;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  max-width: 541px;
  width: 100%;
  background: ${theme.colors.bgSecondary};
  border-radius: 8px;
  padding: 24px;
  border: 1px solid ${theme.colors.border};
  & a {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 24px;
    color: ${theme.colors.text};
  }
  @media (max-width: 630px) {
    max-width: 441px;
  }
  @media (max-width: 486px) {
    max-width: 100%;
    bottom: 0;
    left: 0;
    & a {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
      color: ${theme.colors.text};
    }
  }
`;

export const ButtonSection = styled.div`
  margin-top: auto;
  display: flex;
  & button {
    font-size: 16px;
    margin-right: 16px;
  }
`;

export const ListSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 24px;
  & > div:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.border};
  }
  & > div:not(:first-child) label {
    margin-top: 24px;
  }
  & > div label > div {
    width: 18px;
    height: 18px;
  }
  & > div:first-child div {
    opacity: 50%;
  }
  & > div span {
    font-size: 18px;
    font-weight: 600;
  }
  @media (max-width: 425px) {
    & > div span {
      font-size: 16px;
    }
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & label {
    margin-bottom: 22px;
  }
  & p {
    font-size: 18px;
    color: ${theme.colors.text};
  }
  @media (max-width: 425px) {
    & label {
      margin-bottom: 10px;
    }
    & p {
      font-size: 16px;
    }
  }
`;
