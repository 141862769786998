import { FC } from "react";

export const CheckCircle: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8332 9.73159V10.4983C18.8321 12.2953 18.2503 14.0438 17.1743 15.4831C16.0983 16.9224 14.5859 17.9753 12.8626 18.4848C11.1394 18.9944 9.29755 18.9332 7.6119 18.3104C5.92624 17.6877 4.48705 16.5367 3.50897 15.0291C2.53089 13.5216 2.06633 11.7383 2.18457 9.94519C2.30281 8.15206 2.99751 6.4452 4.16507 5.07915C5.33263 3.71311 6.91049 2.76108 8.66333 2.36505C10.4162 1.96902 12.2501 2.15021 13.8915 2.88159"
        stroke="#EA6B26"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8333 3.83203L10.5 12.1737L8 9.6737"
        stroke="#EA6B26"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
