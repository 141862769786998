import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button, Popover, Radio } from "antd";
import styled, { css } from "styled-components/macro";
import QuestionCircleOutlined from "@ant-design/icons/lib/icons/QuestionCircleOutlined";
import { Check as CheckIcon } from "@styled-icons/bootstrap/Check";

import { ButtonSelect as ButtonSelectOriginal } from "../ButtonSelect/ButtonSelect";
import { IHouseStepPrices, TStepPayload } from "./interfaces";
import { useIsMobile } from "../../utils/hooks/useWindowSize";
import { ClockIcon } from "../Icons/Clock";

export const WizardButtonSelect = styled(ButtonSelectOriginal)<{
  gridTemplate?: string;
}>`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplate || "1fr 1fr 1fr"};
  width: 100%; // Ensure it tries to fill its parent

  button {
    text-align: start;
    max-width: 100%;
    white-space: normal;
  }

  button span {
    vertical-align: middle;
  }

  @media (max-width: 950px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 570px) {
    grid-template-columns: 1fr;
  }
` as typeof ButtonSelectOriginal;

const PriceCalculationRoot = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.colors.borderSecondary};
  padding: 24px;
  gap: 16px;
`;
export const PriceCalculationTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
export const PriceCalculationBlueBox = styled.div`
  background: ${(p) => p.theme.colors.place};
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: ${(p) => p.theme.colors.text};
`;
const PriceLabel = styled.div``;
export const PriceValue = styled.div`
  font-weight: 500;
`;
export const PriceValueSummary = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const PriceCalculation = ({
  values,
  className
}: {
  values: TStepPayload<IHouseStepPrices>;
  className?: string;
}) => {
  const { t } = useTranslation("house");

  return (
    <PriceCalculationRoot className={className}>
      <PriceCalculationTitle>
        {t("wizard.steps.Prices.summary.title")}
      </PriceCalculationTitle>
      <PriceCalculationBlueBox>
        <PriceRow>
          <PriceLabel>
            {className && className.includes("editRentalDataModal")
              ? t(`wizard.steps.Prices.form.price.titleOverview`)
              : t(`wizard.steps.Prices.form.price.title`)}
          </PriceLabel>
          <PriceValue>€{values.price ?? 0}</PriceValue>
        </PriceRow>
        <PriceRow>
          <PriceLabel>
            {t(`wizard.steps.Prices.form.additional_expenses.title`)}
          </PriceLabel>
          <PriceValue>€{values.additional_expenses ?? 0}</PriceValue>
        </PriceRow>
        <PriceRow>
          <PriceLabel>
            {t(`wizard.steps.Prices.form.service_costs.title`)}
          </PriceLabel>
          <PriceValue>€{values.service_costs ?? 0}</PriceValue>
        </PriceRow>
        <PriceRow>
          <PriceLabel>
            {t(`wizard.steps.Prices.summary.monthly_total`)}
          </PriceLabel>
          <PriceValueSummary>
            €
            {[
              values.price,
              values.additional_expenses,
              values.service_costs
            ].reduce((acc, value) => {
              return (
                acc +
                (value && !isNaN(parseInt(value.toString() ?? ""))
                  ? parseInt(value.toString())
                  : 0)
              );
            }, 0)}
          </PriceValueSummary>
        </PriceRow>
      </PriceCalculationBlueBox>
      <PriceCalculationBlueBox>
        <PriceRow>
          <PriceLabel>
            {className && className.includes("editRentalDataModal")
              ? t(`wizard.steps.Prices.form.deposit.titleOverview`)
              : t(`wizard.steps.Prices.form.deposit.title`)}
          </PriceLabel>
          <PriceValue>€{values.deposit ?? 0}</PriceValue>
        </PriceRow>
      </PriceCalculationBlueBox>
    </PriceCalculationRoot>
  );
};

export const RadioGroup = styled(Radio.Group)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 24px;

  .ant-radio-wrapper {
    padding: 24px 16px;
    border: 1px solid #dfe1eb;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 16px !important;

    @media ${(props) => props.theme.media.tablet},
      ${(props) => props.theme.media.phone} {
      padding: 8px 16px;
    }
  }

  .ant-radio-wrapper-checked:not(.ant-radio-wrapper-disabled) {
    border: 1px solid ${(p) => p.theme.colors.primary};
    background: #fff7f3;
  }

  .ant-radio-wrapper-disabled.ant-radio-wrapper-checked {
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
  }
`;

const FloatingButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const HelperContent = styled.div`
  width: 272px;
  ul {
    margin-left: 16px;
  }
  p {
    margin-bottom: 24px;
  }
`;

export const FloatingHelperButton: () => ReactElement = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("wizard");

  return (
    <FloatingButtonContainer>
      <Popover
        placement="bottomLeft"
        title={t("helperButton.title")}
        rootClassName="wizard-helper-popup"
        content={
          <HelperContent
            dangerouslySetInnerHTML={{
              __html: t("helperButton.content")
            }}
          />
        }
        trigger="click"
      >
        <Button
          icon={<QuestionCircleOutlined />}
          style={{
            height: isMobile ? "40px" : "50px"
          }}
          size={isMobile ? "middle" : "large"}
        >
          {t("helperButton.title")}
        </Button>
      </Popover>
    </FloatingButtonContainer>
  );
};

export const EditableStepWrapper = styled.div<{ $showBorder?: boolean }>`
  padding: 24px 32px;
  background: white;
  border-radius: 8px;
`;

export const StepContainerNarrow = styled.div`
  width: 100%;
  max-width: 640px;
  padding: 80px 0 43px;

  ${EditableStepWrapper} {
    background: none;
    padding: 0;
  }

  @media (max-width: 768px) {
    padding: 80px 20px 43px;
  }
`;

export const Root = styled.div<{ $gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap || "1rem"};
  width: 100%;
`;

export const Card = styled.div<{ $padding?: string }>`
  padding: ${({ $padding }) => $padding || "1.5rem 2rem"};
  border-radius: 8px;
  background: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 24px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;

export const CardLeftSide = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const CardStatus = styled.div<{
  $fw?: "weightNormal" | "weightSemiMedium";
}>`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.midGrey};
  font-weight: ${({ theme, $fw }) =>
    $fw ? theme.font[$fw] : theme.font.weightNormal};
`;

export const CardTitle = styled.div<{
  $fw?: "weightNormal" | "weightSemiMedium";
}>`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.hardGray};
  font-weight: ${({ theme, $fw }) =>
    $fw ? theme.font[$fw] : theme.font.weightNormal};
`;

export const CardStatusAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CardRightSide = styled.div<{ $gap?: string }>`
  color: ${({ theme }) => theme.colors.midGrey};
  display: flex;
  align-items: center;
  gap: ${({ $gap }) => $gap || "0.5rem"};
`;

const StatusIconRoot = styled.div<{ $status: "completed" | "pending" }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $status }) =>
    $status === "pending" &&
    css`
      background: #f8ece7;
    `};

  ${({ $status }) =>
    $status === "completed" &&
    css`
      background: #ebf8e7;
    `}
`;
const Check = styled(CheckIcon)`
  width: 30px;
  height: 30px;

  path {
    fill: #32c51a;
  }
`;
const Clock = styled(ClockIcon)`
  path {
    fill: ${(p) => p.theme.colors.primary};
  }
`;
export const StatusIcon = ({ status }: { status: "completed" | "pending" }) => {
  return (
    <StatusIconRoot $status={status}>
      {status === "pending" ? <Clock /> : null}
      {status === "completed" ? <Check /> : null}
    </StatusIconRoot>
  );
};
