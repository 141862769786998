import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { useContextHospi } from "./ContextHospi";
import { getSignedSocketUrl } from "../utils/utilAmplify";
import { Socket, SocketEvents } from "../utils/Socket";

interface ISocketContext {
  instance: Socket | null;
  isConnected?: boolean;
}

export const SocketContext = createContext<ISocketContext>({
  instance: null,
  isConnected: false
});

interface ISocketProvider {
  children: ReactNode;
}

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider: FC<ISocketProvider> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const { currentUserId } = useContextHospi();

  useEffect(() => {
    let unmounted = false;
    if (currentUserId) {
      setIsConnected(false);
      const socket = new Socket(getSignedSocketUrl);

      socket.on(SocketEvents.OPEN, () => {
        if (unmounted) return;
        setSocket(socket);
        setIsConnected(true);
      });

      socket.on(SocketEvents.CLOSE, () => {
        if (unmounted) return;
        setIsConnected(false);
      });

      return () => {
        unmounted = true;
        socket.destroy();
      };
    }
  }, [currentUserId]);

  return (
    <SocketContext.Provider
      value={{
        instance: socket,
        isConnected
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
