import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UserAPI } from "../userApi";
import { useCurrentUser } from "../../utils/helpers";
import { ISearchPreferences, TReview } from "../../interfaces/interfaces";

const getUserPreviewQueryKey = (userId?: string) => ["userPreview", userId];

export const useGetUserPreview = (userId?: string) => {
  return useQuery(
    getUserPreviewQueryKey(userId),
    () => {
      return UserAPI.getUserPreview(userId!);
    },
    {
      enabled: Boolean(userId)
    }
  );
};

export const getUserQueryKey = (userId?: string) => ["user", userId];

// export const useGetUser = (userId?: string) => {
//   return useQuery(getUserQueryKey(userId), () => {
//       if (!userId) return;
//       return UserAPI.getUser(userId);
//     },
//     { enabled: Boolean(userId) }
//   );
// };

const getUserShortDescriptionQueryKey = (userId?: string) => [
  "userShortDescription",
  userId
];

export const useGetUserShortDescription = (
  userId?: string,
  currentUserId?: string
) => {
  return useQuery(
    getUserShortDescriptionQueryKey(userId),
    () => {
      if (!userId) {
        return null;
      }
      return UserAPI.getShortDescription([userId!]).then((response) => {
        if (response?.users_short_description?.[0]) {
          return response.users_short_description[0];
        }
        return null;
      });
    },
    {
      enabled: Boolean(userId) && Boolean(currentUserId)
    }
  );
};
export const useSetUserSearchPreferences = () => {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();

  return useMutation<unknown, unknown, ISearchPreferences>({
    mutationFn: (payload) => {
      return UserAPI.setSearchPreferences(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getUserQueryKey(currentUser?.user_id)
      });
    }
  });
};

export const getReviewsQueryKey = ({ hostId }: { hostId?: string }) => [
  "reviews",
  hostId
];
export const useGetReviews = (
  hostId?: string,
  enabled: boolean = true,
  selectFn?: (res: TReview[]) => TReview[]
) => {
  return useQuery(
    getReviewsQueryKey({ hostId }),
    () => {
      if (!hostId) return;
      return UserAPI.getReviews(hostId);
    },
    {
      enabled: Boolean(hostId) && enabled,
      select: (data) =>
        typeof selectFn === "function" && Array.isArray(data)
          ? selectFn(data)
          : data
    }
  );
};
