import { FILE_UPLOAD_IMAGES_ALLOWED_MIME_TYPES } from "../config/common";

export const getFileUploadImageAllowedExtensions = () => {
  return FILE_UPLOAD_IMAGES_ALLOWED_MIME_TYPES.split(", ").map(
    (type) => type.split("/")[1]
  );
};

export const getFileUploadImageAllowedExtensionsLabel = () => {
  return getFileUploadImageAllowedExtensions()
    .map((v) => v.toUpperCase())
    .join(", ");
};

export const getMaxImageUploadSizeLabel = (size: number) => size + "mb";
