import { css } from "styled-components/macro";

export const DefaultFont = css`
  font-size: ${(props) => props.theme.text.default.size};
  font-weight: ${(props) => props.theme.text.default.weight};
  font-family: ${(props) => props.theme.text.family};
  color: ${(props) => props.theme.text.color.blue};
  line-height: ${(props) => props.theme.text.defaultLineHeight};
  @media ${(props) => props.theme.media.phone} {
    font-size: ${(props) => props.theme.text.default.mobileSize};
    font-weight: ${(props) => props.theme.text.default.mobileWeight};
  }
`;

export const HeaderFont = css`
  font-size: ${(props) => props.theme.text.header.size};
  font-weight: ${(props) => props.theme.text.header.weight};
  font-family: ${(props) => props.theme.text.family};
  color: ${(props) => props.theme.text.color.blue};
  line-height: ${(props) => props.theme.text.defaultLineHeight};
  @media ${(props) => props.theme.media.phone} {
    font-size: ${(props) => props.theme.text.header.mobileSize};
    font-weight: ${(props) => props.theme.text.header.mobileWeight};
  }
`;

export const BannerFont = css`
  font-size: ${(props) => props.theme.text.banner.size};
  font-weight: ${(props) => props.theme.text.banner.weight};
  font-family: ${(props) => props.theme.text.family};
  color: ${(props) => props.theme.text.color.blue};
  line-height: ${(props) => props.theme.text.defaultLineHeight};
  @media ${(props) => props.theme.media.phone} {
    font-size: ${(props) => props.theme.text.banner.mobileSize};
    font-weight: ${(props) => props.theme.text.banner.mobileWeight};
  }
`;
