import { PreviewBlock } from "../PreviewSection";
import { Wrapper } from "./styles";
import { Statistics } from "../Statistics";
import { WeAreHereSection } from "../WeAreHere";
import { SolutionBlock } from "../SolutionBlock";
import { Partners } from "../Partners";
import { HowItWorks } from "../HowItWorks";
import { Review } from "../Review";
import { FAQ } from "../../components/FAQ";
import { HelpStudent } from "../HelpStudent";
import useDetectDevice from "../../utils/hooks/useWindowSize";
import { HeaderSEO } from "../../components/headerSEO";
import { ISeo } from "../../interfaces/interfaces";
import { useTranslation } from "react-i18next";

export const HomePage = () => {
  const device = useDetectDevice();
  const { t } = useTranslation("home");

  const seo = t("seo", { returnObjects: true }) as ISeo;

  return (
    <Wrapper>
      {seo && <HeaderSEO {...seo} />}
      <PreviewBlock mode="home" />
      {!["mobile", "tablet"].includes(device) && <Statistics />}
      <WeAreHereSection />
      <Partners />
      <SolutionBlock />
      <HowItWorks />
      <Review
        reviewText={t("review")}
        btnText={t("buttons.allReviews")}
        authorName={t("reviewAuthor")}
        authorInfo={t("hostedSince")}
      />
      <FAQ />
      <HelpStudent />
    </Wrapper>
  );
};
