export const ABOUT_US = [
  {
    title: "blog",
    link: "/blog"
  },
  {
    title: "team",
    link: "/team"
  },
  {
    title: "workAt",
    link: "/vacancies"
  },
  {
    title: "mission",
    link: "/mission"
  }
];

export const COLLABORATIONS = [
  {
    title: "partners",
    link: "/partners"
  },
  {
    title: "forCities",
    link: "/cities"
  },
  {
    title: "corporations",
    link: "/housing-corporations"
  },
  {
    title: "press",
    link: "/press"
  }
];

export const FOR_HOSTS = [
  {
    title: "register",
    link: "/register"
  },
  {
    title: "howDoesItWork",
    link: "/hospita"
  },
  {
    title: "experienceWithOthers",
    link: "/blog"
  }
];

export const FOR_STUDENTS = [
  {
    title: "register",
    link: "/register"
  },
  {
    title: "howDoesItWork",
    link: "/students"
  },
  {
    title: "experienceWithOthers",
    link: "/blog"
  }
];

export const SUPPORT = [
  {
    title: "faq",
    link: "/faq"
  },
  {
    title: "contact",
    link: "/contact"
  }
];

export const LEGAL = [
  {
    title: "privacy",
    link: "/privacy"
  },
  {
    title: "cookies",
    link: "/cookies"
  },
  {
    title: "contactdetails",
    link: "/contactdetails"
  }
];
