import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router";
// import { useAnalytics } from "../GAnalytics";

interface WrapperProps {
  currentUserID: string | undefined;
  currentUserType: string | undefined;
  children: ReactNode;
}

export function GAnalyticsWrapper(props: WrapperProps) {
  const location = useLocation();
  // const { initialized } = useAnalytics();
  useEffect(() => {
    window.dataLayer.push({
      event: "page_view",
      page: { page_type: location.pathname + location.search },
      user_id: props.currentUserID,
      user_type: props.currentUserType
    });
  }, [location.pathname]);

  return <>{props.children}</>;
}
