import { FC, useRef, useState } from "react";
import { ArrowDown } from "../../components/Header/styles";
import { Content, HiddenContent, Item, Wrapper, StyledPrefix } from "./styles";

interface IAccordionItem {
  title: string;
  content: string;
}

const AccordionItem: FC<IAccordionItem> = ({ content, title }) => {
  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef<any>(null);

  return (
    <Wrapper>
      <Item onClick={() => setOpen(!open)} open={open} id="bold">
        {title}
        <StyledPrefix>
          <ArrowDown size="2em" up={open} />
        </StyledPrefix>
      </Item>
      <HiddenContent ref={ref}>{content}</HiddenContent>
      <Content open={open} height={ref.current?.offsetHeight}>
        {content}
      </Content>
    </Wrapper>
  );
};

interface IAccordion {
  questions: IAccordionItem[];
}

export const Accordion: FC<IAccordion> = ({ questions }) => {
  return (
    <div>
      {questions.map((item, index) => (
        <AccordionItem key={index} {...item} />
      ))}
    </div>
  );
};
