import styled, { css } from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Person } from "@styled-icons/bootstrap/Person";
import { ChevronDown } from "@styled-icons/bootstrap/ChevronDown";

import { theme } from "styles/theme";

export const PROFILE_NAVBAR_ICONS_SIZES = {
  desktop: { width: 7.25, height: 3.625 },
  mobile: { width: 5.8125, height: 3 }
};

export const HeaderWrapper = styled.header`
  background: ${theme.colors.bgSecondary};
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);

  & li a.active {
    color: ${theme.colors.text};
  }
`;

export const HeaderContainer = styled.div`
  max-width: 1360px;
  height: ${theme.sizes.headerHeight};
  margin: 0 auto;
  padding: 0 80px; // isn't im em units as has to fit root platform page styles

  @media (max-width: 930px) {
    padding: 0 40px;
  }
  @media (max-width: 850px) {
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    height: ${theme.sizes.mobileHeaderHeight};
  }
`;

export const StyledNavbar = styled.nav`
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${theme.media.tablet} {
    margin: 0;
    & svg {
      z-index: 20;
    }
  }
  @media ${theme.media.phone} {
    margin: 0;
    & svg {
      z-index: 20;
    }
  }
`;

export const NotAuthorizedUserContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-shrink: 1;
  flex-grow: 0;
`;

export const ListWrapper = styled.ul`
  display: flex;
  justify-content: space-between; /* Spread items evenly */
  align-items: center; /* Vertically align items */
  padding: 0; /* Reset padding */
  list-style: none; /* Remove list bullets */
`;

export const ContainerPopupMenu = styled.ul`
  position: absolute;
  top: 44px; // random value
  right: 0;
  border-radius: 12px;
  border: 1px solid ${theme.colors.border};
  background-color: ${theme.colors.place};
  list-style: none;
  z-index: 1000;
  width: 224px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin: 0;
  overflow: hidden;

  & a {
    display: block;
    width: 100%;
    padding: 0.5em 1em;
    &:hover {
      background-color: ${theme.colors.secondary};
    }
  }
  &.helpNavbar {
    top: 46px;
    right: 50%;
    transform: translateX(50%);
    a {
      font-weight: 400;
      &.active {
        font-weight: ${theme.font.weightSemiMedium};
      }
      &:hover {
        color: ${theme.colors.primary};
      }
    }
    li:first-child a {
      padding: 1em 1em 0.5em;
    }
    li:last-child a {
      padding: 0.5em 1em 1em;
    }
  }

  & button.close {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
    border: none;
    background: transparent;
    color: ${theme.colors.text};
    transition: all 0.3s ease;
    font-size: 1rem;
    &:hover {
      color: ${theme.colors.primary};
    }
  }
  & button.back {
    position: absolute;
    top: 1.143em;
    cursor: pointer;
    border: none;
    background: transparent;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.875rem;
    color: ${theme.colors.text};
    transition: all 0.3s ease;
    left: 0.8571em;
    &:hover {
      color: ${theme.colors.primary};
    }
  }

  @media ${theme.media.tablet} {
    right: -21px; // isn't im em units as has to fit navbar padding
    top: -11px;
    border-top-right-radius: 0;
  }
  @media ${theme.media.phone} {
    right: -21px;
    top: -11px;
    border-top-right-radius: 0;
  }
`;

export const MenuItem = styled.li<{ $isUserAuthorized?: boolean }>`
  flex-grow: 1; /* Allow items to grow and take equal space */
  line-height: 1.75;
  & a {
    font-size: 1rem;
    font-weight: 500;
    color: ${theme.colors.textGray};
    &:hover {
      color: ${theme.colors.text};
    }
  }
  &:not(:last-child) {
    margin-right: 1.875em;
  }
  &:last-child {
    margin-right: 0.5em;
  }
  &:first-child {
    margin-left: 0.5em;
  }
  div {
    bottom: 1.25em;
  }

  ${({ $isUserAuthorized }) =>
    !$isUserAuthorized &&
    css`
      @media (max-width: 1024px) {
        &:not(:last-child) {
          margin-right: 1em;
        }
      }
    `}
`;

export const MenuWrapper = styled.div`
  position: relative;
  z-index: 30;
  & > div {
    position: absolute;
    height: 300px;
    width: 300px;
    right: -100%;
    top: 0;
  }
`;

export const PersonIcon = styled(Person)`
  cursor: pointer;
  color: ${theme.colors.text};
`;

export const MobileLogoLink = styled(NavLink)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Item = styled.li`
  font-size: 1rem;
  background-color: transparent;
  font-weight: 400;
  cursor: pointer;
  padding: 0;
  list-style-type: none;
  color: ${theme.colors.text};
  transition: color 0.3s;

  &:hover {
    color: ${theme.colors.primary};
  }
  a {
    display: block;
    width: 100%;
    color: ${theme.colors.text};
    &:hover {
      background-color: ${theme.colors.secondary};
    }
    &.active {
      font-weight: ${theme.font.weightSemiMedium};
    }
  }
  &.buttonItem {
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      display: inline-block;
    }
    &:hover {
      background-color: ${theme.colors.secondary};
    }
  }
  &.buttonStyledItem {
    a {
      color: ${theme.colors.text};
      font-weight: 400;
      &.active {
        font-weight: ${theme.font.weightSemiMedium};
      }
    }
    &:hover {
      background-color: ${theme.colors.secondary};
      a {
        color: ${theme.colors.primary};
      }
    }
  }
`;

export const ItemText = styled.li<{ $padding?: string }>`
  padding: ${({ $padding }) => $padding || "1em 0 0.5em 1em"};
  list-style-type: none;
  font-size: 1rem;
  color: ${theme.colors.text};
  user-select: none;

  & > p {
    margin: 0;
  }
  & > p:last-child {
    word-break: break-all;
    font-weight: 600;
  }
`;

export const LngButton = styled.button<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${theme.colors.text};
  font-size: 1rem;
  padding: 0.5em 1em;
  width: 100%;
  transition: 0.3s;

  img {
    margin-right: 0.5em;
    border-radius: 50%;
    object-fit: cover;
  }
  &:last-child {
    padding-bottom: 1em;
  }
  &:hover {
    color: ${theme.colors.primary};
  }

  ${({ $isActive }) =>
    !!$isActive &&
    css`
      font-weight: ${theme.font.weightSemiMedium};
    `}
`;

export const AvatarImg = styled.img`
  border-radius: 50%;
  object-fit: cover;
  display: inline-block;
  width: 2.25rem;
  height: 2.25rem;

  @media ${theme.media.tablet} {
    width: 1.875rem;
    height: 1.875rem;
  }
  @media ${theme.media.phone} {
    width: 1.875rem;
    height: 1.875rem;
  }
`;

export const AuthButtons = styled.div`
  display: inline-flex;
  align-items: center;
  max-width: min-content;

  & button:first-child {
    margin-right: 1em;
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

export const ArrowDown = styled(ChevronDown)<{
  up?: boolean;
}>`
  font-size: 0.875rem;
  transition: 0.2s;
  padding: 0.4em;
  cursor: pointer;

  ${({ up }) =>
    !!up &&
    css`
      transform: rotateX(180deg);
    `}
`;

export const ProfileNavbarIcons = styled(Item)`
  width: ${PROFILE_NAVBAR_ICONS_SIZES.desktop.width}rem;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid ${theme.colors.border};
  background-color: white;
  padding: 0.625em 1.125em;
  transition: 0.2s;
  display: inline-flex;
  align-items: center;
  position: relative;
  &:hover {
    color: ${theme.colors.primary};
  }

  @media ${theme.media.tablet} {
    padding: 0.5em 0.75em;
    width: ${PROFILE_NAVBAR_ICONS_SIZES.mobile.width}rem;
  }
  @media ${theme.media.phone} {
    padding: 0.5em 0.75em;
    width: ${PROFILE_NAVBAR_ICONS_SIZES.mobile.width}rem;
  }
`;

export const StyledBurger = styled.div<{
  $open: boolean;
  $isRightMenuOpen?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: ${({ $isRightMenuOpen }) => ($isRightMenuOpen ? 10 : 20)};

  &:focus {
    outline: none;
  }

  div {
    width: 1.5rem;
    height: 2px;
    background: ${theme.colors.text};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ $open }) => ($open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ $open }) => ($open ? "0" : "1")};
    }

    :nth-child(3) {
      transform: ${({ $open }) => ($open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const SecondaryNavbarContainer = styled.div`
  padding-top: 3.25em;
  a:hover {
    color: ${theme.colors.primary};
  }
`;
