import { createContext, FC, ReactNode, useContext, useState } from "react";

export type TAutoTranslation = {
  house_id: string;
  description?: {
    gb: string;
    nl: string;
  };
  household_description?: {
    gb: string;
    nl: string;
  };
  reviews_text?: { [key: string]: { gb: string; nl: string } }; // key is a review created_at value
};

interface IContextAutoTranslations {
  translations: TAutoTranslation[];
  updateTranslation: (translation: TAutoTranslation) => void;
}

const ContextAutoTranslations = createContext<IContextAutoTranslations>({
  translations: [],
  updateTranslation: (_) => {}
});

export const useContextAutoTranslations = (): IContextAutoTranslations => {
  return useContext<IContextAutoTranslations>(ContextAutoTranslations);
};

export const AutoTranslationsProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [translations, setTranslations] = useState<TAutoTranslation[]>([]);

  const updateTranslation = (translation: TAutoTranslation) => {
    const indexOfTranslation = translations.findIndex(
      (t) => t.house_id === translation.house_id
    );

    if (indexOfTranslation === -1) {
      setTranslations((prev) => [...prev, translation]);
    } else {
      setTranslations((prev) => {
        return prev.map((t) =>
          t.house_id === translation.house_id ? translation : t
        );
      });
    }
  };

  return (
    <ContextAutoTranslations.Provider
      value={{
        translations,
        updateTranslation
      }}
    >
      {children}
    </ContextAutoTranslations.Provider>
  );
};
