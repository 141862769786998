import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div`
  display: flex;
  max-width: 1360px;
  width: 100%;
  margin: 64px auto;
  & > div {
    margin: 0 80px;
    display: flex;
    width: 100%;
    border-radius: 8px;
    background: ${theme.colors.bgSecondary};
  }
`;

export const Item = styled.div`
  flex: 25%;
  padding: 24px;
  & div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & p {
      margin: 0;
      font-size: 16px;
      color: ${theme.colors.textGray};
      font-weight: 500;
    }
    & p:first-child {
      font-weight: 800;
      font-size: 30px;
      color: ${theme.colors.primary};
    }
    & div {
      top: 0;
      left: -30px;
      position: absolute;
      width: 1px;
      height: 100%;
      background: ${theme.colors.border};
    }
  }
`;
