import { QueryCache, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => console.error("queryCache:error", error?.message)
  }),
  defaultOptions: {
    mutations: {
      retry: 0
    },
    queries: {
      staleTime: 1000 * 60 * 10, // cache stale time 10 minutes
      retry: 0
    }
  }
});

export default queryClient;
