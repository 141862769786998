import styled from "styled-components/macro";
import { MixinPlace } from "../mixins";

export const ContainerSelectedValue = styled.div`
  ${MixinPlace};
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
`;

export const PopupSelect = styled.div`
  position: absolute;
  width: max-content;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.place};
  opacity: 0;
  transition: 0.7s;
  right: 1em;
  z-index: 200;
  font-size: 14px;
  top: calc(
    1 * ${(props) => props.theme.font.size} + 3 *
      ${(props) => props.theme.sizes.input.padding} + 2px
  );

  &.active {
    opacity: 1;
  }
  & a {
    display: block;
    width: 100%;
    &:hover {
      background-color: ${(props) => props.theme.colors.secondary};
    }
  }
`;

export const CountryName = styled.div<{ showCountryName: boolean }>`
  font-weight: 600;
  padding-left: 10px;
  display: ${(props) => (props.showCountryName ? "block" : "none")};
`;

export const ItemLang = styled.span`
  cursor: pointer;
  width: 100%;
  padding: 4px;
  &.active {
    background-color: ${(props) => props.theme.colors.activeRadioBox};
  }
  color: ${(props) => props.theme.colors.text};
`;

export const StyleFlag = {
  width: "1.5em",
  height: "1.5em",
  marginRight: "0.2em"
};
