import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div`
  margin: 64px auto;
  max-width: 1360px;
  width: 100%;
  .slick-slide {
    margin-left: 27px;
  }

  & img {
    filter: grayscale(100%);
  }
  .slick-list {
    margin-left: -27px;
    filter: grayscale(100%);
    opacity: 50%;
  }
  .slick-track {
    display: flex;
  }

  & > div {
    position: relative;
    margin: 0 80px;
    .ant-carousel {
      padding: 0 10px;
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
    }
    @media (max-width: 910px) {
      margin: 0 40px;
    }
    @media (max-width: 820px) {
      margin: 0 20px;
    }
  }
`;

export const ItemList = styled.div<{ url: string }>`
  height: 100px;
  width: 100%;
  & a {
    height: 100px;
  }
`;

export const Title = styled.h2`
  color: ${theme.colors.textDimmed};
  font-size: 20px;
  margin-bottom: 24px;
  text-align: center;
`;

export const Arrow = styled.div`
  position: absolute;
  z-index: 30;
  width: 14px;
  height: 14px;
  border-left: 2px solid ${theme.colors.textDimmed};
  border-top: 2px solid ${theme.colors.textDimmed};
  bottom: calc(50% - 7px);
  cursor: pointer;
`;
export const ArrowNext = styled(Arrow)`
  right: 0;
  transform: rotate(135deg);
`;
export const ArrowPrev = styled(Arrow)`
  left: 0;
  transform: rotate(-45deg);
`;
