import styled, { css } from "styled-components/macro";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div<{ $isImg: boolean }>`
  background: ${theme.colors.bgSecondary};
  & > div {
    margin: 0 auto;
    max-width: ${({ $isImg }) => ($isImg ? "70%" : "1360px")};
    padding: ${({ $isImg }) => ($isImg ? "42.5px 0" : "46px 80px")};
    display: flex;
    align-items: center;

    img {
      border-radius: 8px;
    }
    @media (max-width: 930px) {
      padding: ${({ $isImg }) => ($isImg ? "42.5px 0" : "46px 40px")};
    }
    @media (max-width: 850px) {
      padding: ${({ $isImg }) => ($isImg ? "42.5px 0" : "46px 20px")};
    }
    @media (max-width: 811px) {
      flex-direction: column;
      align-items: center;
    }
    @media ${theme.media.phone} {
      max-width: none;
      padding: ${({ $isImg }) => ($isImg ? "42.5px 20px" : "46px 20px")};
    }
  }
`;

export const Content = styled.div<{ $isImg: boolean }>`
  margin-left: ${({ $isImg }) => ($isImg ? "80px" : "64px")};
  display: flex;
  flex-direction: column;
  & p {
    margin: ${({ $isImg }) => ($isImg ? "24px 0" : "30px 0 24px")};
    font-size: ${({ $isImg }) => ($isImg ? "20px" : "24px")};
    color: ${theme.colors.text};
    max-width: ${({ $isImg }) => ($isImg ? "78.7%" : "none")};
  }
  & div > div {
    margin-right: ${({ $isImg }) => ($isImg ? 0 : "64px")};
    display: flex;
    flex-direction: column;
    & span {
      font-size: 20px;
    }
  }
  & > div {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 1080px) {
    p {
      max-width: none;
    }
  }
  @media (max-width: 811px) {
    margin-top: 30px;
    margin-left: 0;
  }
  ${({ $isImg }) =>
    $isImg &&
    css`
      svg {
        width: 32px;
        height: auto;
      }
      & div > div span {
        line-height: ${theme.text.defaultLineHeight};
        &:first-child {
          font-size: 32px;
        }
      }
    `}
  @media ${theme.media.phone} {
    & p {
      font-size: ${({ $isImg }) => ($isImg ? "18px" : "24px")};
    }
    & div > div span {
      font-size: ${({ $isImg }) => ($isImg ? "18px" : "20px")};
      &:first-child {
        font-size: ${({ $isImg }) => ($isImg ? "24px" : "20px")};
      }
    }
  }
  @media (max-width: 450px) {
    & div > div {
      margin: ${({ $isImg }) => ($isImg ? 0 : "0 0 24px")};
    }
    & > div {
      & span {
        text-align: center;
      }
      flex-direction: column;
    }
  }
`;
