import { Wizard } from "../index";
import {
  WIZARD_HOST_DEFAULT_STATE,
  WIZARD_HOST_STEPS_CONFIGURATION,
  WIZARD_HOST_STEPS_ORDER
} from "./constants";
import { useCallback } from "react";
import { UserAPI } from "../../../API/userApi";
import {
  IWizardHostState,
  T_WIZARD_HOST_STEP,
  T_WIZARD_HOST_STEPS_PATH,
  WIZARD_HOST_STEPS_PATHS
} from "../interfaces";
import { useTranslation } from "react-i18next";
import { useContextHospi } from "../../../context/ContextHospi";
import { HouseAPI } from "../../../API/houseApi";
import { generatePath, useNavigate } from "react-router-dom";
import { HOST_HOUSE_WIZARD_ROUTE } from "../../../utils/constants";
import { FloatingHelperButton } from "../components";

export const HostWizard = () => {
  const {
    cognitoUser,
    updateProfile,
    userProfile,
    setNotFinishedRequiredHouseWizard
  } = useContextHospi();
  const { t, i18n } = useTranslation("host");
  const navigate = useNavigate();

  return (
    <Wizard<
      T_WIZARD_HOST_STEP,
      T_WIZARD_HOST_STEPS_PATH,
      IWizardHostState,
      typeof WIZARD_HOST_STEPS_PATHS
    >
      wizardType="host"
      stepsOrder={WIZARD_HOST_STEPS_ORDER}
      stepsPathsInState={WIZARD_HOST_STEPS_PATHS}
      stepsConfiguration={WIZARD_HOST_STEPS_CONFIGURATION}
      defaultState={WIZARD_HOST_DEFAULT_STATE}
      getWizardState={useCallback(() => {
        return UserAPI.wizardHostGetState();
      }, [])}
      resetWizardState={useCallback((id) => {
        return UserAPI.wizardHostReset(id);
      }, [])}
      finishWizard={useCallback(() => {
        window.dataLayer.push({
          event: "Step_1_host_sign_up",
          user_id: cognitoUser?.id,
          user_type: "host",
          status: userProfile?.verification_status
        });
        return UserAPI.wizardHostFinish();
      }, [])}
      getStepTitle={(stepName) => {
        return t(`wizard.steps.${stepName}.title`, {
          defaultValue: null
        });
      }}
      getStepDescription={(stepName) => {
        return t(`wizard.steps.${stepName}.description`, {
          defaultValue: null
        });
      }}
      // getOverviewStepTitle={(stepName) => t(`wizard.steps.${stepName}.titleOverview`, {defaultValue: null})}
      additionalStepContent={<FloatingHelperButton />}
      onFinished={useCallback(async () => {
        const user = await UserAPI.getUser(cognitoUser?.id!);
        updateProfile(user);
        const wizard = await HouseAPI.createWizard("description");
        setNotFinishedRequiredHouseWizard(wizard);
        navigate(
          generatePath(HOST_HOUSE_WIZARD_ROUTE, {
            houseId: wizard.house_id,
            wizardType: wizard.wizard_type,
            lng: i18n.language
          })
        );
      }, [cognitoUser?.id, updateProfile, i18n.language, navigate])}
    />
  );
};
