import styled from "styled-components/macro";
import Icon, { ISvg } from "./Icon";

const Svg = styled(Icon)`
  width: 38px;
  height: 39px;
`;

export const ImageAdd = ({ className }: ISvg) => (
  <Svg viewBox="0 0 38 39" fill="none" className={className}>
    <path
      d="M21 5.5H5C3.93913 5.5 2.92172 5.92143 2.17157 6.67157C1.42143 7.42172 1 8.43913 1 9.5V29.5M1 29.5V33.5C1 34.5609 1.42143 35.5783 2.17157 36.3284C2.92172 37.0786 3.93913 37.5 5 37.5H29C30.0609 37.5 31.0783 37.0786 31.8284 36.3284C32.5786 35.5783 33 34.5609 33 33.5V25.5M1 29.5L10.172 20.328C10.9221 19.5781 11.9393 19.1569 13 19.1569C14.0607 19.1569 15.0779 19.5781 15.828 20.328L21 25.5M33 17.5V25.5M33 25.5L29.828 22.328C29.0779 21.5781 28.0607 21.1569 27 21.1569C25.9393 21.1569 24.9221 21.5781 24.172 22.328L21 25.5M21 25.5L25 29.5M29 5.5H37M33 1.5V9.5M21 13.5H21.02"
      stroke="#9CA3AF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
