import { FC, useState } from "react";

import { ToggleTitle, Wrapper, ToggleDiv } from "./styles";

interface IToggleList {
  titles: string[];
  content: any;
  setActive?: (i: number) => void;
}

export const ToggleList: FC<IToggleList> = ({ titles, content, setActive }) => {
  const [selectedTitle, setSelectedTitle] = useState<number>(0);

  const Element = content[selectedTitle];

  return (
    <Wrapper>
      <ToggleDiv>
        {titles.map((title, i) => (
          <ToggleTitle
            key={`${title}${i}`}
            selected={i === selectedTitle}
            onClick={() => {
              setSelectedTitle(i);
              setActive && setActive(i);
            }}
          >
            {title}
          </ToggleTitle>
        ))}
      </ToggleDiv>
      <div>{Element}</div>
    </Wrapper>
  );
};
