import { EventEmitter } from "./EventEmitter";

const log = (...args: any[]) => {
  console.info("Socket:", ...args);
};

export enum SocketEvents {
  RECONNECTING = "reconnecting",
  OPEN = "open",
  CLOSE = "close",
  ERROR = "error",
  MESSAGE = "message"
}

type SocketURLGeneratorType = () => Promise<string>;

export class Socket extends EventEmitter {
  private _generateSocketUrl: SocketURLGeneratorType;
  private _socket: WebSocket | null = null;
  private _isClosed = false;
  private _reconnectAttempts = 0;
  private _notificationAfterReconnectAttempts = 3;
  private _reconnectionManagerIntervalId: number | null = null;
  private _reconnectionManagerInterval = 1000;
  private _lastReconnectAttempt: null | number = null;
  private _reconnectAttemptTimeout = 10 * 1000;

  private _messagesQueue: {
    data: any;
    callback?: () => void;
    createdAt: number;
  }[] = [];

  private _isReconnecting = false;
  get connected() {
    return this._socket?.readyState === WebSocket.OPEN;
  }
  constructor(generateSocketUrl: SocketURLGeneratorType) {
    super();
    this._generateSocketUrl = generateSocketUrl;

    this._generateSocketUrl()
      .then((url) => {
        // log("Socket: new WebSocket(signedUrl)", url);
        this._socket = new WebSocket(url);
        this._initListeners();
        this._initReconnectionManager();
      })
      .catch(this._handleSignedSocketUrlError);
  }

  _initListeners() {
    this._socket?.addEventListener("open", this._onOpen.bind(this));
    this._socket?.addEventListener("close", this._onClose.bind(this));
    this._socket?.addEventListener("error", this._onError.bind(this));
    this._socket?.addEventListener("message", this._onMessage.bind(this));
  }

  _onOpen(event: Event) {
    // log("_onOpen", event);
    if (this._isClosed) return;

    if (this._isReconnecting) {
      this._isReconnecting = false;
      this.emit(SocketEvents.RECONNECTING, false);
    }

    this._reconnectAttempts = 0;
    this.emit(SocketEvents.OPEN, event);

    const messagesQueue = this._messagesQueue;
    this._messagesQueue = [];
    messagesQueue.forEach(({ data, callback }) => {
      this.send(data, callback);
    });
  }

  _onClose(event: CloseEvent) {
    // log("_onClose", event);
    if (this._isClosed) return;

    this.emit(SocketEvents.CLOSE, event);
  }

  _onError(event: Event) {
    log("_onError", event);
    if (this._isClosed) return;

    this.emit(SocketEvents.ERROR, event);
  }

  _onMessage(event: MessageEvent) {
    const eventData = this._decode(event.data);
    // log("_onMessage", eventData ? eventData : event.data);

    if (this._isClosed) return;

    if (eventData) {
      this.emit(SocketEvents.MESSAGE, eventData);
    }
  }

  send(data: any = null, callback: () => void = () => undefined) {
    // log("send", data);

    if (this._socket && this.connected) {
      try {
        this._socket.send(this._encode(data));
      } catch (e) {
        console.error("cannot emit event. error:", e);
      }
    } else {
      this._messagesQueue.push({
        data,
        callback,
        createdAt: Date.now()
      });
    }
  }

  _encode(data: any) {
    return JSON.stringify(data);
  }

  _decode(data: any) {
    try {
      return JSON.parse(data);
    } catch {
      return false;
    }
  }

  private _initReconnectionManager() {
    this._reconnectionManagerIntervalId = setInterval(() => {
      const isNotConnected =
        !this._socket ||
        this._socket?.readyState === WebSocket.CLOSED ||
        this._socket?.readyState === WebSocket.CLOSING;

      if (
        isNotConnected &&
        (!this._lastReconnectAttempt ||
          Date.now() - this._lastReconnectAttempt >
            this._reconnectAttemptTimeout)
      ) {
        this._lastReconnectAttempt = Date.now();
        this._reconnect();
      }
    }, this._reconnectionManagerInterval) as unknown as number;
  }

  _reconnect() {
    // log("reconnecting...");
    this._isReconnecting = true;
    this.emit(SocketEvents.RECONNECTING, true);
    const prevSocket = this._socket;
    prevSocket?.close();

    this._generateSocketUrl()
      .then((url) => {
        if (this._isClosed) {
          return;
        }
        // log("Socket: new WebSocket(signedUrl)", url);

        this._socket = new WebSocket(url);
        this._reconnectAttempts += 1;
        log("_reconnectAttempts", this._reconnectAttempts);
        this._initListeners();

        if (
          this._reconnectAttempts === this._notificationAfterReconnectAttempts
        ) {
          // TODO: show antd notification
        }
      })
      .catch(this._handleSignedSocketUrlError);
  }

  _handleSignedSocketUrlError(error: any) {
    console.error("SocketProvider: cannot get signed socket url", error);
  }
  destroy() {
    if (this._reconnectionManagerIntervalId) {
      clearInterval(this._reconnectionManagerIntervalId);
    }
    this._isClosed = true;
    this._socket?.close();
  }

  static createTraceId() {
    return Date.now() + " - " + Math.random();
  }
}
