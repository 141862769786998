import {
  ChatStatusFilter,
  IChat,
  IChatUserCounters,
  IUserOnlineStatus,
  TMessage
} from "../interfaces/interfacesChat";

import { ServiceApi } from "../utils/utilAmplify";
import query from "../utils/query";
import { ServiceName } from "../config/amplify";

class ChatsAPIClass {
  private readonly api: ServiceApi;
  constructor() {
    this.api = new ServiceApi(ServiceName.Protected);
  }
  getUserChats(
    userId: string,
    chatStatus: ChatStatusFilter,
    amount: number,
    nextBatchToken: string | null
  ): Promise<{ chats: IChat[]; next_batch_token: string | null }> {
    return this.api.get(
      `chats/user-chats/${userId}?` +
        query.stringify({
          next_batch_token: nextBatchToken,
          chat_status: chatStatus,
          amount
        }),
      {}
    );
  }
  getChat(chatId: string): Promise<IChat> {
    return this.api.get(`chats/chat/${chatId}`);
  }
  getChatMessages(
    chatId: string,
    beforeDt: string = ""
  ): Promise<{ messages: TMessage[] }> {
    return this.api.get(
      `messages/chat/${chatId}${beforeDt ? `?before_dt=${beforeDt}` : ""}`
    );
  }
  reportChat(chatId: string, reporterId: string, reporteeId: string) {
    return this.api.post("reports/report_chat", {
      chat_id: chatId,
      reporter_id: reporterId,
      reportee_id: reporteeId
    });
  }
  acceptChat(chatId: string) {
    return this.api.post(`chats/accept-chat/${chatId}`);
  }
  getUserCounters(): Promise<IChatUserCounters> {
    return this.api.get("chats/user-chats-counter");
  }
  getUserMessagesCounter(): Promise<IChatUserCounters> {
    return this.api.get("messages/counter");
  }
  archive(chatId: string, payload?: { message: string }) {
    return this.api.post(`chats/archive-chat/${chatId}`, payload);
  }
  unArchive(chatId: string) {
    return this.api.post(`chats/un-archive/${chatId}`);
  }
  getUserOnlineStatus(userId: string): Promise<IUserOnlineStatus> {
    return this.api.get(`user-online/${userId}`);
  }
}

export const ChatsAPI = new ChatsAPIClass();
