import { API, Auth } from "aws-amplify";
import { Signer } from "@aws-amplify/core";
import { notification } from "antd";
import { ServiceName } from "../config/amplify";
import {
  ICognitoAttributes,
  ICognitoUser,
  SOCIAL_AUTH_PROVIDERS
} from "../interfaces/interfaces";

export const getAuthorizationToken = async (): Promise<string> => {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const getHeaderAuth = async () => {
  try {
    return {
      Authorization: `Bearer ${await getAuthorizationToken()}`
    };
  } catch {
    return {};
  }
};

export const getSignedSocketUrl = async () => {
  const credentials = await Auth.currentCredentials();
  if (!credentials.accessKeyId) {
    throw Error("User is not authorized");
  }
  const accessInfo = {
    access_key: credentials.accessKeyId,
    secret_key: credentials.secretAccessKey,
    session_token: credentials.sessionToken
  };

  return Signer.signUrl(process.env.REACT_APP_WEBSOCKET, accessInfo);
};

export const clearLocalStorage = () => {
  const arrayItem: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i)?.startsWith("Cognito"))
      arrayItem.push(localStorage.key(i) ?? "");
  }
  arrayItem.filter(Boolean).forEach((item) => localStorage.removeItem(item));
};

export const createCognitoUserFromAttributes = (
  attributes: ICognitoAttributes
): ICognitoUser => {
  let isSocialAuthUser = false;
  if (attributes.identities) {
    const identities = JSON.parse(attributes.identities);
    if (
      identities[0]?.providerName &&
      SOCIAL_AUTH_PROVIDERS.includes(identities[0].providerName)
    )
      isSocialAuthUser = true;
  }

  return {
    id: attributes.sub,
    email: attributes.email,
    email_verified: attributes.email_verified,
    role: attributes.profile,
    locale: attributes.locale,
    isSocialAuthUser
  };
};

type ExtraParams = { showNotificationOnError?: boolean; [key: string]: any };

export class ServiceApi {
  constructor(private readonly apiName: ServiceName) {
    this.apiName = apiName;
  }

  get(path: string, init: ExtraParams = {}) {
    const { showNotificationOnError, ...rest } = init;
    const options = ServiceApi.getRequestOptions({ showNotificationOnError });

    const promise = API.get(this.apiName, path, rest);

    promise.catch((e) => ServiceApi.defaultErrorHandler(e, options));

    return promise;
  }
  post(
    path: string,
    body: { [key: string]: any } = {},
    init: ExtraParams = {}
  ) {
    const { showNotificationOnError, ...rest } = init;
    const options = ServiceApi.getRequestOptions({ showNotificationOnError });

    const promise = API.post(this.apiName, path, {
      ...rest,
      body
    });

    promise.catch((e) => ServiceApi.defaultErrorHandler(e, options));

    return promise;
  }
  del(path: string, body: { [key: string]: any } = {}, init: ExtraParams = {}) {
    const { showNotificationOnError, ...rest } = init;
    const options = ServiceApi.getRequestOptions({ showNotificationOnError });

    const promise = API.del(this.apiName, path, {
      ...rest,
      body
    });

    promise.catch((e) => ServiceApi.defaultErrorHandler(e, options));

    return promise;
  }
  patch(path: string, body: { [key: string]: any }, init: ExtraParams = {}) {
    const { showNotificationOnError, ...rest } = init;
    const options = ServiceApi.getRequestOptions({ showNotificationOnError });

    const promise = API.patch(this.apiName, path, {
      ...rest,
      body
    });

    promise.catch((e) => ServiceApi.defaultErrorHandler(e, options));

    return promise;
  }

  static defaultErrorHandler(error: any, options: ExtraParams = {}) {
    if (API.isCancel(error)) return;
    console.log("Error object:", error);
    const status = error.response?.status;
    const message = error.response?.data?.message;
    const urlPath = window.location.pathname; // Access the global window.location object
    const pathSegments = urlPath.split("/"); // Assuming the URL structure is something like "/gb/page"
    const langCode = pathSegments[1]; // Get the language code from the URL

    let errorMessage;
    switch (langCode) {
      case "gb":
        errorMessage = "Please sign in to view this";
        break;
      case "nl":
        errorMessage = "Je moet ingelogd zijn om dit te kunnen zien";
        break;
      default:
        errorMessage = "Please sign in to view this"; // Fallback message
    }

    if (status === 401) {
      ServiceApi.getNotifier().error({
        message: errorMessage
      });
      return;
    }

    if (status && status.toString()[0] === "5") {
      // console.error(error);
      ServiceApi.getNotifier().error({
        message: error.toString() // TODO: on production, change to "Something went wrong with the server. Please try again later."
      });
      return;
    }

    if (options.showNotificationOnError) {
      ServiceApi.getNotifier().error({
        message: message
          ? typeof message === "string"
            ? message
            : JSON.stringify(message)
          : error.toString() // TODO: on production convert to user friendly messages
      });
    }
  }

  static getRequestOptions(options: { showNotificationOnError?: boolean }) {
    const finalOptions = {
      showNotificationOnError: true
    };
    if (options.showNotificationOnError !== undefined) {
      finalOptions.showNotificationOnError = options.showNotificationOnError;
    }

    return finalOptions;
  }

  static getNotifier() {
    return window.hospiNotifier ?? notification;
  }
}
