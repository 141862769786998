import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { publicRoutes } from "routes";
import { LANGUAGES } from "utils/constants";
import { Item } from "../ProfileMenu/styles";
import { LanguageSection, LeftSideMenu } from "./styles";
import SelectLanguage from "../../SelectLanguage";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const TabletMenu = ({ open, setOpen }: IProps) => {
  const { t, i18n } = useTranslation("header");

  return (
    <LeftSideMenu open={open}>
      {publicRoutes.map((item) => (
        <Item key={item.path} onClick={() => setOpen((prev) => !prev)}>
          <NavLink to={`/${i18n.language}${item.path.slice(5)}`}>
            {t(`menu.${item.keyLocalize}`)}
          </NavLink>
        </Item>
      ))}
      <LanguageSection>
        <SelectLanguage
          items={LANGUAGES}
          showCountryName={true}
          className="mobile-lang"
        />
      </LanguageSection>
    </LeftSideMenu>
  );
};
export default TabletMenu;
