import { FC, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, NavLink } from "react-router-dom";

import { useContextHospi } from "context/ContextHospi";
import { LOGIN_ROUTE, REGISTER_ROUTE } from "utils/constants";

import { Item, StyledMenu } from "./styles";

const ProfileMenu: FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {
  const { t, i18n } = useTranslation("header");
  const { cognitoUser } = useContextHospi();

  return (
    <StyledMenu open={open}>
      {!cognitoUser && (
        <>
          <Item>
            <NavLink
              to={generatePath(LOGIN_ROUTE, {
                lng: i18n.language
              })}
              onClick={() => setOpen((prev) => !prev)}
            >
              {t("menu.signIn")}
            </NavLink>
          </Item>
          <Item>
            <NavLink
              to={generatePath(REGISTER_ROUTE, {
                lng: i18n.language
              })}
              onClick={() => setOpen((prev) => !prev)}
            >
              {t("menu.signUp")}
            </NavLink>
          </Item>
        </>
      )}
    </StyledMenu>
  );
};

export default ProfileMenu;
