type BaseAction = {
  type: string;
};

export const createReducer = <S, A extends BaseAction>(actionsMap: {
  [key: string]: (state: S, action: A) => S;
}) => {
  return (state: S, action: A) => {
    if (actionsMap[action.type]) {
      return actionsMap[action.type](state, action);
    }

    return state;
  };
};
