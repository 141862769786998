export const QuotesIco = (
  <svg
    width="45"
    height="36"
    viewBox="0 0 45 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.028 0.19873C5.684 5.38273 0.5 13.8787 0.5 23.2387C0.5 30.8707 5.108 35.3347 10.436 35.3347C15.476 35.3347 19.22 31.3027 19.22 26.5507C19.22 21.7987 15.908 18.3427 11.588 18.3427C10.724 18.3427 9.572 18.4867 9.284 18.6307C10.004 13.7347 14.612 7.97473 19.22 5.09473L13.028 0.19873ZM37.796 0.19873C30.596 5.38273 25.412 13.8787 25.412 23.2387C25.412 30.8707 30.02 35.3347 35.348 35.3347C40.244 35.3347 44.132 31.3027 44.132 26.5507C44.132 21.7987 40.676 18.3427 36.356 18.3427C35.492 18.3427 34.484 18.4867 34.196 18.6307C34.916 13.7347 39.38 7.97473 43.988 5.09473L37.796 0.19873Z"
      fill="#EA6B26"
    />
  </svg>
);
