import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "gb",
    supportedLngs: ["gb", "nl"],
    debug: process.env.NODE_ENV === "development",
    returnObjects: true,
    detection: {
      order: ["path", "cookie", "localStorage"],
      cache: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"] // languages to not persist (cookie, localStorage)
    },
    interpolation: {
      escapeValue: false
    },
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"]
  });

export default i18n;
