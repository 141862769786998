import { useState, useEffect } from "react";

interface Size {
  width: number;
  height: number;
}

type DetectedDevice = "mobile" | "tablet" | "laptop" | "fullHd";

export function useSizeWindow(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

function useDetectDevice(): DetectedDevice {
  const windowSize = useSizeWindow();

  return detectDevice(windowSize.width);
}

function detectDevice(windowWidth: number): DetectedDevice {
  if (windowWidth <= 570) {
    return "mobile";
  } else if (windowWidth <= 768) {
    return "tablet";
  } else if (windowWidth <= 1440) {
    return "laptop";
  } else {
    return "fullHd";
  }
}

export function useMediaQuery(mediaQuery: string): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mql = window.matchMedia(mediaQuery);
    const handleChange = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    if (mql.addEventListener) {
      mql.addEventListener("change", handleChange);
    } else {
      mql.addListener(handleChange);
    }

    setMatches(mql.matches);

    return () => {
      if (mql.removeEventListener) {
        mql.removeEventListener("change", handleChange);
      } else {
        mql.removeListener(handleChange);
      }
    };
  }, [mediaQuery]);

  return matches;
}

export function useIsMobile() {
  return useMediaQuery("(max-width: 768px)");
}

export default useDetectDevice;
