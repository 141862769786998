import styled, { css } from "styled-components/macro";
import { HTMLAttributes } from "react";

import { BannerFont, DefaultFont, HeaderFont } from "../styles/fonts";
import { MixinFont, MixinPlace } from "./mixins";

// The root div for all rootpage pages.
export const ContentDiv = styled.div`
  background-color: white;
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto 0;

  padding: 5% 15% 10% 15%;

  @media ${(props) => props.theme.media.phone} {
    padding: 2rem 0rem 2rem 0rem;
  }

  p {
    ${DefaultFont}
  }

  h3 {
    ${DefaultFont};
    color: ${(props) => props.theme.text.color.orange};
  }

  h2 {
    ${HeaderFont}
  }

  h1 {
    &:first-child {
      margin-bottom: 2em;
    }
    ${BannerFont}
  }

  & > section:not(:last-child),
  & > div:not(:last-child) {
    margin-bottom: 10em;
    @media ${(props) => props.theme.media.phone} {
      margin-bottom: 3em;
    }
  }
`;

interface IFSectionProps extends HTMLAttributes<HTMLDivElement> {
  reverse?: boolean;
  grow?: number;
  notCentering?: boolean;
}

export const FlexSection = styled.div<IFSectionProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};

  align-items: ${(props) => (props.notCentering ? "normal" : "center")};
  gap: 2rem;

  & > * {
    flex: 1 1 ${(props) => (props.grow ? props.grow : "50")}%;
  }
`;

interface IHSectionProps extends HTMLAttributes<HTMLDivElement> {
  readonly outline?: boolean;
}

// A one line div for hilighted information (phone numbers etc). With or without outline.
export const HighlightedSection = styled.div<IHSectionProps>`
  ${DefaultFont};

  width: fit-content;
  white-space: nowrap;

  margin-bottom: 0.6em;

  & > svg {
    vertical-align: middle;
    margin-right: 1ex;
  }

  background-color: ${(props) => props.theme.colors.bgSecondary};
  padding: 1rem;

  border-width: 1px;
  border-color: ${(props) => props.theme.colors.borderSecondary};
  border-style: ${(props) => (props.outline ? "solid" : "hidden")};
  border-radius: 8px;
`;

export const MainFlow = styled.div<{
  isIOS: boolean;
  isSafari: any;
  marginAuto: boolean;
  $isShowHeader: boolean;
  $isShownStickyNavbar: boolean;
}>`
  & #bold {
    font-weight: 600 !important;
  }
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-areas:
    "hospi-header"
    "hospi-main";
  grid-template-rows: auto auto;
  #hospi-header {
    grid-area: hospi-header;
  }
  #hospi-main {
    grid-area: hospi-main;
    width: 100%;
    height: calc(
      var(--app-height) -
        ${(p) => (p.$isShowHeader ? p.theme.sizes.headerHeight : "0px")}
    );
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: auto;

    @media (max-width: 768px) {
      height: calc(
        var(--app-height) -
          ${(p) => (p.$isShowHeader ? p.theme.sizes.mobileHeaderHeight : "0px")}
      );
    }

    & > div:first-child {
      margin: ${(p) => (p.marginAuto ? "0 auto" : "")};
      min-height: calc(
        var(--app-height) -
          ${(p) => (p.$isShowHeader ? p.theme.sizes.headerHeight : "0px")}
      );

      @media (max-width: 768px) {
        min-height: calc(
          var(--app-height) -
            ${(p) =>
              p.$isShowHeader ? p.theme.sizes.mobileHeaderHeight : "0px"}
        );
        ${({ $isShownStickyNavbar, $isShowHeader, theme }) =>
          $isShownStickyNavbar &&
          css`
            min-height: calc(
              var(--app-height) - ${theme.sizes.mobileStickyNavbarHeight} -
                ${$isShowHeader ? theme.sizes.mobileHeaderHeight : "0px"}
            );
          `}
      }
    }
  }
`;

// Use for Login page
export const ContainerLogin = styled.div`
  min-height: calc(var(--app-height) - 146px) !important;
  max-width: 336px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto 50px auto;

  @media (max-width: 500px) {
    justify-content: flex-start;
    height: calc(var(--app-height) + 50px);
  }
`;

// Redirect to login
export const ContainerRedirect = styled.div`
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.border};
  background-color: ${(props) => props.theme.colors.place};
  padding: 24px;
  max-width: 384px;
  width: 100%;
`;

interface DialogProps {
  readonly maxWidthDlg?: string;
  readonly $gap?: string;
}

export const DialogWrapper = styled.div<DialogProps>`
  display: grid;
  gap: ${({ $gap }) => $gap || "16px"};
  padding: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);

  & > .buttons {
    display: inline-grid;
    gap: 16px;
    justify-self: end;
    grid-template-columns: repeat(2, max-content);
  }

  @media (max-width: 768px) {
    & > .buttons {
      & > button {
        width: 150px;
        white-space: normal;
        height: auto;
      }
    }
  }

  @media (max-width: 400px) {
    padding: 16px;
    & > .buttons {
      justify-self: center;
      grid-template-columns: 1fr;
      & > button {
        min-width: 60vw;
      }
    }
  }
`;

export const ContainerValue = styled.div<
  { maxWidth?: string } & HTMLAttributes<HTMLDivElement>
>`
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.border};
  background-color: ${(props) => props.theme.colors.place};
  padding: 24px;
  max-width: ${(props) => props.maxWidth || "initial"};
  width: 100%;
  margin-bottom: 24px;
  word-break: break-word;
  @media ${(props) => props.theme.media.phone} {
    margin-bottom: 24px;
    padding: 24px 8px;
    word-break: break-word;
  }
  position: relative;
`;

// Use page Login, FC InputCalendar, Input, Select, MultiSelect, SelectLanguage
export const StyledBlock = styled.div<{
  w?: string;
  grow?: string;
  p?: string;
}>`
  display: block;
  position: relative;
  width: ${(props) => props.w || "100%"};
  padding: ${(props) => props.p || "initial"};
  flex-grow: ${(props) => props.grow || "0"};
  line-height: 1.8em;
`;
export interface IFlex extends HTMLAttributes<HTMLDivElement> {
  readonly justify?: string;
  readonly align?: string;
  readonly direction?: string;
  readonly wrap?: string;
  readonly gap?: string;
  readonly padding?: string;
  readonly margin?: string;
  readonly grow?: number;
  readonly width?: string;
}
// Use page Login, Home, StudentProfile, Contact
// Use FC InputCalendar, Calendar, MultiSelect, Menu, SelectLanguage, Range
export const FlexBox = styled.div<IFlex>`
  display: flex;
  align-items: ${(props) => props.align || "center"};
  justify-content: ${(props) => props.justify || "center"};
  flex-direction: ${(props) => props.direction || "row"};
  flex-wrap: ${(props) => props.wrap || "wrap"};
  gap: ${(props) => props.gap || "0"};
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
  width: ${(props) => props.width || "initial"};
  flex-grow: ${(props) => (props.grow === undefined ? 1 : props.grow)};
`;

export const PullGrid = styled.div<{ $gap?: string }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ $gap }) => $gap || "24px"};
  justify-items: left;
`;

export const CorrectPullRefresh = styled.div`
  width: 100%;
  & .ptr__children {
    transform: none !important;
  }
`;

export const OverviewInfoContainer = styled.div<
  {
    flexBasis?: string;
    maxWidth?: string;
    margin?: string;
  } & HTMLAttributes<HTMLDivElement>
>`
  overflow-wrap: break-word;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.borderSecondary};
  background-color: ${(props) => props.theme.colors.white};
  padding: 32px;
  flex-basis: ${(props) => props.flexBasis || "auto"};
  max-width: ${(props) => props.maxWidth || "none"};
  margin: ${(props) => props.margin || 0};
  word-break: break-word;
  color: ${(props) => props.theme.colors.hardGray};
  height: fit-content;

  @media ${(props) => props.theme.media.tablet} {
    flex-basis: auto;
    max-width: none;
    margin-bottom: 40px;
    margin-right: 0;
  }

  @media ${(props) => props.theme.media.phone} {
    padding: 24px;
    flex-basis: auto;
    max-width: none;
    margin-bottom: 40px;
    margin-right: 0;
  }

  & > div:last-child {
    margin-bottom: 0;
  }

  & > * {
    word-break: break-word;
  }
`;

export const CardsContainer = styled.div<{ $gap?: string | 0 }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ $gap }) => $gap ?? "40px"};

  @media ${({ theme }) => theme.media.tablet} {
    grid-template-columns: 1fr;
  }

  @media ${({ theme }) => theme.media.phone} {
    grid-template-columns: 1fr;
  }
`;

export const ContainerSelectedValue = styled(FlexBox).attrs<IFlex>(
  ({ justify }) => ({
    justify: justify || "flex-start"
  })
)<{ error?: boolean }>`
  ${MixinFont};
  ${MixinPlace};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  padding: 0.1em 2em 0.1em 0.1em;
  cursor: pointer;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `};
`;

// STYLEGUIDE 2024:
export const PopupButtonsContainer = styled.div<{ $padding?: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: ${({ $padding }) => $padding ?? 0};

  @media ${({ theme }) => theme.media.phone} {
    flex-direction: column;
    justify-content: flex-start;
    button {
      width: 100%;
    }
  }
`;
