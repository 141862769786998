import { FC, useState } from "react";
import { Image, Upload as UploadOriginal } from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { UploadChangeParam } from "antd/es/upload/interface";

import { ImageAdd } from "components/Icons/ImageAdd";
import { FILE_UPLOAD_IMAGES_ALLOWED_MIME_TYPES } from "../../config/common";
import { getBase64 } from "utils/helpers";
import { getFileUploadImageAllowedExtensionsLabel } from "../../utils/uploadAvatar";

import { DefaultFont } from "../../styles/fonts";

const Root = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.div`
  ${DefaultFont};
  font-size: 16px;
  margin-bottom: 4px;

  & > span {
    color: ${(p) => p.theme.colors.primary};
    font-weight: 500;
  }
`;
const AllowedTypes = styled.div`
  color: ${(p) => p.theme.colors.midGrey};
  font-weight: 500;
`;
const Upload = styled(UploadOriginal)<{ $imagePresent: boolean }>`
  .ant-upload-select {
    width: 100% !important;
    height: 250px !important;
  }

  ${(p) =>
    p.$imagePresent
      ? css`
          width: 250px !important;
          height: 250px !important;

          .ant-upload-list-item-container {
            width: 100% !important;
            height: 100% !important;
          }
        `
      : ""};
`;

export const AvatarUploader: FC<{
  onFileSelect?: (file: File | undefined) => void;
  initialImageUrl?: string;
  readonly?: boolean;
}> = ({ onFileSelect, initialImageUrl, readonly }) => {
  const [fileList, setFileList] = useState<UploadFile<RcFile>[]>(
    initialImageUrl
      ? [
          {
            uid: "initial-id" + Math.random(),
            url: initialImageUrl,
            name: `fileName`
          }
        ]
      : []
  );
  const [imageError, setImageError] = useState<string | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const onChange = async (info: UploadChangeParam<UploadFile>) => {
    setImageError(null);

    const selectedFile = info.fileList?.[0]?.originFileObj ?? undefined;
    if (selectedFile) {
      const formatIsSupported = FILE_UPLOAD_IMAGES_ALLOWED_MIME_TYPES.split(",")
        .map((tr) => tr.trim())
        .includes(selectedFile.type);
      if (!formatIsSupported) {
        setImageError(
          `${t(
            "step.Avatar.imageError"
          )} ${getFileUploadImageAllowedExtensionsLabel()}`
        );
        return;
      }
      // if (
      //   selectedFile.size > getImageUploadFileSize(FILE_UPLOAD_IMAGES_MAX_MB_SIZE)
      // ) {
      //   setImageError(
      //     `${t("step.Avatar.imageSizeError")} ${getMaxImageUploadSizeLabel(
      //       FILE_UPLOAD_IMAGES_MAX_MB_SIZE
      //     )}`
      //   );
      //   return;
      // }

      setFileList(info.fileList);
      onFileSelect?.(selectedFile);
    } else {
      onFileSelect?.(undefined);
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url;
    if (!src) {
      src = await getBase64(file.originFileObj as RcFile);
    }
    if (src) {
      setPreviewImage(src);
    }
  };

  const onDelete = () => {
    setFileList([]);
    onFileSelect?.(undefined);
  };
  const { t } = useTranslation("wizard");

  return (
    <Root>
      <ImgCrop
        quality={1}
        minZoom={0.5}
        rotationSlider={true}
        zoomSlider={true}
        showReset={true}
        maxZoom={10}
        modalTitle={t("step.Avatar.uploadModal.title")}
        resetText={t("step.Avatar.uploadModal.reset")}
        modalOk={t("step.Avatar.uploadModal.ok")}
        modalCancel={t("step.Avatar.uploadModal.cancel")}
      >
        <Upload
          listType="picture-card"
          accept={FILE_UPLOAD_IMAGES_ALLOWED_MIME_TYPES}
          customRequest={(options) => {
            options?.onSuccess?.({});
          }}
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          onRemove={onDelete}
          disabled={readonly}
          $imagePresent={fileList.length !== 0}
          multiple={false}
        >
          {fileList.length === 0 && (
            <div>
              <ImageAdd />
              <Message>
                <span>{t("step.Avatar.upload")}</span> {t("step.Avatar.drag")}
              </Message>
              <AllowedTypes>
                {getFileUploadImageAllowedExtensionsLabel()}{" "}
                {/*{t("step.Avatar.upTo")}{" "}*/}
                {/*{getMaxImageUploadSizeLabel(FILE_UPLOAD_IMAGES_MAX_MB_SIZE)}*/}
              </AllowedTypes>
            </div>
          )}
        </Upload>
      </ImgCrop>
      {imageError ? <div style={{ color: "red" }}>{imageError}</div> : null}
      {previewImage ? (
        <div style={{ display: "none" }}>
          <Image.PreviewGroup
            preview={{
              visible: true,
              onVisibleChange: () => setPreviewImage(null)
            }}
          >
            <Image src={previewImage} alt="" />
          </Image.PreviewGroup>
        </div>
      ) : null}
    </Root>
  );
};
