import { Title, Wrapper } from "./styles";
import { StyledButton } from "components/generic";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MISSION_ROUTE } from "utils/constants";
export const SolutionBlock = () => {
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div>
        <div>
          <Title>
            {t("solution.title.p1")} <span>{t("solution.title.p2")}</span>{" "}
            {t("solution.title.p3")}
          </Title>
          <p>{t("solution.text")}</p>
          <StyledButton
            p="16px 32px"
            onClick={() => navigate(`${MISSION_ROUTE}`)}
          >
            {t("buttons.mission")}
          </StyledButton>
        </div>
      </div>
    </Wrapper>
  );
};
