import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div`
  background: ${theme.colors.bgSecondary};
  & > div {
    margin: 0 auto;
    max-width: 1360px;
    padding: 40px 80px;
    display: flex;
    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    & > div:last-child {
      align-items: flex-end;
    }
    & p {
      font-size: 32px;
      color: ${theme.colors.text};
    }
    & img {
      width: 100%;
    }
    @media (max-width: 930px) {
      padding: 46px 40px;
    }
    @media (max-width: 850px) {
      & p {
        font-size: 24px;
      }
      padding: 46px 20px;
    }
    @media (max-width: 811px) {
      flex-direction: column-reverse;
      & > div:last-child {
        align-items: center;
        margin-bottom: 24px;
      }
    }
    @media (max-width: 425px) {
      & button {
        width: 100%;
      }
    }
  }
`;
