import { IStepBase, IWizardMetaData } from "./interfaces";

import isEqual from "lodash/isEqual";

export const getActiveStepNumber = <
  TStep extends string,
  TStepPath extends string,
  TState extends {
    metadata: IWizardMetaData<TStepPath>;
  } & {
    [key in TStepPath]?: IStepBase;
  },
  TStepPathsInState extends {
    [key in TStep]?: TStepPath;
  }
>(
  state: TState,
  stepsPathsInState: TStepPathsInState,
  order: TStep[]
): number => {
  let isFirstStepWithPath = true;
  for (let idx = 0; idx < order.length; idx++) {
    const stepName = order[idx];

    let stepPath: TStepPath | null | undefined = null;
    if (stepsPathsInState[stepName]) {
      stepPath = stepsPathsInState[stepName];
    }

    if (stepPath) {
      if (isFirstStepWithPath && !state[stepPath]?.metadata.started) {
        return 0;
      }

      if (
        (state[stepPath]?.metadata.started &&
          !state[stepPath]?.metadata.finished) ||
        (!state[stepPath]?.metadata.started &&
          !state[stepPath]?.metadata.finished)
      ) {
        return idx;
      }

      isFirstStepWithPath = false;
    }
  }
  return order.length - 1;
};

export const convertBeErrorsToFormErrors = (
  beErrors: { loc: (string | number)[]; msg: string }[]
) => {
  return beErrors.reduce(
    (
      acc: { [key: string]: string },
      error: { loc: (string | number)[]; msg: string }
    ) => {
      const pathParts = error.loc.slice(1);
      const path = pathParts
        .filter((part, index) => {
          if (index === pathParts.length - 1 && typeof part === "number") {
            return false;
          }
          return true;
        })
        .join(".");

      return {
        ...acc,
        [path]: error.msg
      };
    },
    {}
  );
};

export const getStepErrorsFromResponse = (errorResponse: any) => {
  if (errorResponse?.response?.status === 422) {
    if (errorResponse.response.data.detail) {
      return convertBeErrorsToFormErrors(errorResponse.response.data.detail);
    }
  }
  return null;
};

export const getChangedFields = <T extends { [key in string]: any }>(
  oldObj: T | null,
  newObj: T
): T => {
  if (oldObj === null) {
    return newObj;
  }
  const changed = {} as T;

  for (const newObjKey in newObj) {
    if (!isEqual(newObj[newObjKey], oldObj[newObjKey])) {
      changed[newObjKey] = newObj[newObjKey];
    }
  }

  return changed;
};

export const isReadonlyStepField = <T>(
  fieldKey: keyof T,
  readonly?: boolean,
  enabledFields?: { [key in keyof T]?: boolean }
) => {
  if (readonly) {
    return readonly;
  }
  if (enabledFields) {
    return !enabledFields[fieldKey];
  }
  return false;
};

export const createCheckIsReadonlyStepField = <T>(
  readonly: boolean | undefined,
  enabledFields: { [key in keyof T]?: boolean } | undefined
) => {
  return (field: keyof T) => {
    return isReadonlyStepField<T>(field, readonly, enabledFields);
  };
};
