export const ClockIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6321_8022)">
        <path
          d="M8.00065 15.1673C11.6826 15.1673 14.6673 12.1826 14.6673 8.50065C14.6673 4.81875 11.6826 1.83398 8.00065 1.83398C4.31875 1.83398 1.33398 4.81875 1.33398 8.50065C1.33398 12.1826 4.31875 15.1673 8.00065 15.1673Z"
          stroke="#EA6B26"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 4.5V8.5L10.6667 9.83333"
          stroke="#EA6B26"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6321_8022">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
